import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Block from 'components/blocks/Block'
import CreateAppView from 'components/views/CreateAppView'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import IconButton from 'components/buttons/IconButton'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import { APP_LIST_LIMIT } from 'models/App'
import { Installation, useAppQuery, useInstallationsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'

const defaultAction = [ {
  description: '',
  icon: 'arrow-right',
  isIconAlwaysVisible: true
} ]

function AppOverviewPage() {
  const queryParams = useQueryParams()
  const { openView } = useViewDispatch()

  const { appId } = useParams<{ appId: string }>()
  const id = queryParams.get('id') || appId
  const queryVariables = {
    filter: {
      appId: { eq: id }
    }
  }

  const {
    data: { app } = {},
    loading,
    error
  } = useAppQuery({
    variables: {
      id
    }
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsListLoading,
    error: installationsListError
  } = useInstallationsListQuery({
    variables: queryVariables
  })

  const installation: Installation | undefined = useMemo(
    () => (installationsList as Installation[]).find((inst) => inst.appId === id),
    [ id, installationsList ]
  )

  const title = app?.name || 'App'

  const onEditApp = () => {
    if (installation) {
      openView({
        title: `Edit ${installation.app.kind === 'PROJECT' ? 'Project' : 'App'}`,
        component: CreateAppView,
        params: {
          initialValues: installation.app,
          queryVariables: {
            limit: APP_LIST_LIMIT,
            order: [ { position: 'asc' } ]
          }
        },
        style: 'PANEL'
      })
    }
  }

  return (
    <PageLoader
      data={app}
      loading={loading || installationsListLoading}
      error={error || installationsListError}
    >
      <TitleBlock
        heading={title}
        hideLastCrumb={2}
        primaryElements={(
          <Flex gap={8}>
            <IconButton description="Edit Project" name="edit" onClick={onEditApp} size={24} variant="dark" />
          </Flex>
        )}
      />
      <Block direction="column" gap={36} width={{ md: '100%' }}>
        <Flex direction="column" gap={24}>
          <Text fontWeight="bold">Get Started</Text>
          <Grid columns={3} gap={24}>
            <MediaCard
              titlePosition="top"
              width="full"
              title="Edit Data Model"
              text="Manage your schema & data sources"
              media="graph"
              to={`/~workspace/project/${appId}/data`}
              actions={defaultAction}
            />
            <MediaCard
              titlePosition="top"
              width="full"
              title="Manage Access"
              text="Invite team members to this project"
              media="group"
              to={`/~workspace/project/${appId}/access`}
              actions={defaultAction}
            />
          </Grid>
        </Flex>
      </Block>
    </PageLoader>
  )
}

export default AppOverviewPage
