import React, { useContext } from 'react'

import Block from 'components/blocks/Block'
import HintBox from 'components/hints/HintBox'
import ResourcesList from 'components/views/graph/ResourcesList'
import TextLink from 'components/links/TextLink'
import TitleBlock from 'components/blocks/TitleBlock'
import WorkspaceContext from 'components/contexts/WorkspaceContext'

function GraphPage() {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  return (
    <>
      <TitleBlock heading="Data" />
      <Block direction="column" width={{ md: '100%' }}>
        <HintBox headline="Advanced Feature">
          This feature must be used carefully. Please contact
          {' '}
          <TextLink href="mailto:support@dashx.com">support@dashx.com</TextLink>
          {' '}
          for help.
        </HintBox>
      </Block>
      <Block direction="column" width={{ md: '100%' }}>
        <ResourcesList app={null} workspaceId={currentWorkspace.id} />
      </Block>
    </>
  )
}

export default GraphPage
