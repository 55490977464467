import React, { Fragment } from 'react'

import Box from 'components/layout/Box'
import Flex, { FlexProps } from 'components/layout/Flex'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { styled } from 'styles/stitches'
import type { Crumb } from 'lib/generateBreadcrumbs'
import type { FontSize } from 'styles/theme'
import type { Space } from 'styles/primitives/spaces'

type ComputedCrumb = Partial<Crumb>

type BreadcrumbOwnProps = {
  crumbs: ComputedCrumb[],
  fontSize?: FontSize,
  separator?: React.ReactNode,
  hideLastCrumb?: false | number,
  appendStickyCrumb?: ComputedCrumb,
  isSticky?: boolean
}

type BreadcrumbsProps = FlexProps & BreadcrumbOwnProps

const SEPARATOR_HORIZONTAL_SPACING: Space = 20

const Separator = styled(Box, {
  marginLeft: SEPARATOR_HORIZONTAL_SPACING
})

function Breadcrumbs({
  crumbs,
  fontSize = 14,
  gap = SEPARATOR_HORIZONTAL_SPACING,
  separator = '/',
  hideLastCrumb,
  appendStickyCrumb,
  isSticky
}: BreadcrumbsProps) {
  let computedCrumbs = crumbs

  if (appendStickyCrumb) {
    computedCrumbs = computedCrumbs.concat(appendStickyCrumb)
  }

  return (
    <Flex gap={gap} grow={1} style={{ width: 0 }} alignItems="center">
      {computedCrumbs.map((crumb, index, { length }) => {
        if (hideLastCrumb && hideLastCrumb >= (length - index)) return null
        const isLast = index === length - 1
        const renderSeparator = isLast && isSticky ? null : (
          <Separator as="span">
            {separator}
          </Separator>
        )

        return (
          <Fragment key={crumb.name}>
            <Text color="dark500" fontSize={fontSize} truncate>
              <TextLink to={crumb.fullPath} mode="subtle">
                <Text as="span" color={isSticky && isLast ? 'dark900' : 'dark500'}>
                  {crumb.renderedName || crumb.name}
                </Text>
              </TextLink>
              {renderSeparator}
            </Text>
          </Fragment>
        )
      })}

    </Flex>
  )
}

export type { BreadcrumbOwnProps }
export default Breadcrumbs
