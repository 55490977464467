import React, { useContext, useState } from 'react'
import startCase from 'lodash/startCase'
import { Field, Form, FormProps } from 'react-final-form'
import type { Decorator, FormApi } from 'final-form'

import App, { AppPlatform, AppTechnology as AppTechnologyEnum } from 'models/App'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import Icon from 'components/icons/Icon'
import IconInput from 'components/inputs/IconInput'
import MediaCard from 'components/mediaCard/MediaCard'
import SearchBar from 'components/searchbar/SearchBar'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { CreateProductInput, ProductPlatform, ProductsListDocument, ProductsListQueryVariables, ProductTechnology, UpdateProductInput, useCreateProductMutation, useUpdateProductMutation } from 'generated/schema'
import type { ViewProps } from 'components/views'

type FormValues = Partial<CreateProductInput> | UpdateProductInput

type ViewParamsType = {
  initialValues: FormValues,
  queryVariables: ProductsListQueryVariables
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const platformTypes = [
  {
    name: AppPlatform.BROWSER,
    icon: 'globe',
    description: 'Website, Blog or Portal'
  }, {
    name: AppPlatform.MOBILE,
    icon: 'phone',
    description: 'Native iOS/Android, React Native, Flutter'
  }, {
    name: AppPlatform.SERVER,
    icon: 'app-database-hub',
    description: 'Node.js, Python, PHP, Ruby, Rust, Go'
  }
]

const technologyTypes = {
  [AppPlatform.BROWSER]: [
    {
      name: 'React.js',
      description: 'Single page application written in React.js',
      identifier: AppTechnologyEnum.REACT,
      icon: 'react-colored'
    }, {
      name: 'Angular.js',
      description: 'Single page application written in Angular.js',
      identifier: AppTechnologyEnum.ANGULAR,
      icon: 'angular-colored'
    }, {
      name: 'Vue.js',
      description: 'Single page application written in Vue.js',
      identifier: AppTechnologyEnum.VUE,
      icon: 'vue-colored'
    }, {
      name: 'Remix',
      description: 'Single page application written in Remix.js',
      identifier: AppTechnologyEnum.REMIX,
      icon: 'remix-colored'
    }, {
      name: 'Other Framework',
      description: 'Any JavaScript / TypeScript framework',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'javascript-colored'
    }, {
      name: 'Static HTML',
      description: 'Web app written using HTML, JS & CSS',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'html-colored'
    }
  ],

  [AppPlatform.MOBILE]: [
    {
      name: 'Native iOS',
      description: 'Mobile app written in Swift or Objective-C',
      identifier: AppTechnologyEnum.NATIVE_IOS,
      icon: 'native-ios-colored'
    }, {
      name: 'Native Android',
      description: 'Mobile app written in Kotlin or Java',
      identifier: AppTechnologyEnum.NATIVE_ANDROID,
      icon: 'native-android-colored'
    }, {
      name: 'React Native',
      description: 'Cross platform app written using React Native',
      identifier: AppTechnologyEnum.REACT_NATIVE,
      icon: 'react-colored'
    }, {
      name: 'Flutter',
      description: 'Cross platform app written using Flutter',
      identifier: AppTechnologyEnum.FLUTTER,
      icon: 'flutter-colored'
    }, {
      name: 'Other Mobile',
      description: 'Mobile app written in any other language',
      identifier: AppTechnologyEnum.OTHER,
      icon: 'phone-colored'
    }
  ],

  [AppPlatform.SERVER]: [
    {
      name: 'Node,js',
      description: 'Server-side app written in Node.js',
      identifier: AppTechnologyEnum.NODE,
      icon: 'node-colored'
    }, {
      name: 'Python',
      description: 'Server-side app written in Python',
      identifier: AppTechnologyEnum.PYTHON,
      icon: 'python-colored'
    }, {
      name: 'PHP',
      description: 'Server-side app written in PHP',
      identifier: AppTechnologyEnum.PHP,
      icon: 'php-colored'
    }, {
      name: 'Ruby',
      description: 'Server-side app written in Ruby',
      identifier: AppTechnologyEnum.RUBY,
      icon: 'ruby-colored'
    }, {
      name: 'Rust',
      description: 'Server-side app written in Rust',
      identifier: AppTechnologyEnum.RUST,
      icon: 'rust-colored'
    }, {
      name: 'Go',
      description: 'Server-side app written in Go',
      identifier: AppTechnologyEnum.GO,
      icon: 'go-colored'
    }, {
      name: 'Java',
      description: 'Server-side app written in Java',
      identifier: AppTechnologyEnum.JAVA,
      icon: 'java-colored'
    }, {
      name: 'Other Server',
      description: 'Server-side app written in any other language',
      identifier: AppTechnologyEnum.OTHER,
      icon: 'language-colored'
    }
  ]
}

const stepSubTitles = [
  'Step 1: What is this product being built on?',
  'Step 2: Enter details'
]

const PLATFORM_OPTIONS: {label: string, value: string}[] = [
  { label: 'Android', value: 'ANDROID' },
  { label: 'Browser', value: 'BROWSER' },
  { label: 'iOS', value: 'IOS' },
  { label: 'Server', value: 'SERVER' }
]

function CreateProductView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const isUpdating = 'id' in initialValues

  const title = `${isUpdating ? 'Edit' : 'New'} Product`

  const hideSteps = isUpdating

  const [ currentStep, setCurrentStep ] = useState(hideSteps ? 1 : 0)

  const isFirstStep = currentStep === 0
  const isFinalStep = currentStep === 1

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  const [ platform, setPlatform ] = useState<ProductPlatform | undefined>(undefined)
  const [ technology, setTechnology ] = useState<ProductTechnology | undefined>(undefined)

  const [ createProduct ] = useCreateProductMutation({
    onCompleted: onRequestClose
  })

  const [ updateProduct ] = useUpdateProductMutation({
    onCompleted: onRequestClose
  })

  const handleCreateProduct = useSubmitHandler(createProduct, {
    update: {
      strategy: 'APPEND',
      query: ProductsListDocument,
      dataKey: 'productsList',
      mutation: 'createProduct',
      queryVariables
    }
  })

  const handleUpdateProduct = useSubmitHandler(updateProduct, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateProduct',
      typename: 'Product'
    }
  })

  const handleSubmit = (
    values: FormValues, form: FormProps<FormValues>['form']
  ) => {
    if ('id' in initialValues) {
      return handleUpdateProduct(values as UpdateProductInput, form as FormApi<any>)
    }
    return handleCreateProduct(values as CreateProductInput)
  }

  const FirstStep = () => {
    const [ searchText, setSearchText ] = useState('')
    return (
      <Flex direction="column" gap={24}>
        <SearchBar placeholder="Quick Search..." onChange={(e) => setSearchText(typeof e === 'string' ? e : e.target.value)} />
        {platformTypes.map((p) => {
          const filteredTechnologies = technologyTypes[p.name].filter(
            (t) => t.name.toLowerCase().includes(searchText.toLowerCase())
          )

          if (!filteredTechnologies.length) {
            return null
          }

          return (
            <Flex direction="column" gap={16}>
              <Text fontWeight="bold">{startCase(p.name.toLowerCase())}</Text>
              <Grid columns={3} gap={16}>
                {filteredTechnologies.map((t) => (
                  <MediaCard
                    active={technology === t.name}
                    key={t.name}
                    text={t.description}
                    titlePosition="top"
                    media={(
                      <Icon
                        reqContext={require.context('assets/images/technologies', true, /\.svg$/)}
                        name={t.icon}
                        size={32}
                      />
                    )}
                    onClick={() => {
                      setPlatform(p.name)
                      setTechnology(t.identifier)
                      handleStepForward()
                    }}
                    title={t.name}
                    width="full"
                  />
                ))}
              </Grid>
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const FinalStep = () => (
    <Flex direction="column" gap={16}>
      <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" helptext="Example: Website, Acme Marketing, Site, Acme Portal" />
      <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" helpText="Used in code. Avoid modifying this." />
      <Field
        component={IconInput}
        name="icon"
        label="Icon"
        placeholder="Choose Icon"
        size="small"
        type="text"
      />
      <Field
        name="summary"
        label="Summary"
        helpText={'Add notes regarding the product\'s intended use cases. Supports markdown.'}
        component={TextAreaInput}
        size="small"
      />
      <>
        <Field
          component={TextInput}
          disabled
          format={(value) => startCase(value?.toLowerCase())}
          helpText={isUpdating ? 'If you wish to change this, create a new project.' : ''}
          label={isUpdating ? 'Built Using' : ''}
          name="technology"
          size="small"
          type={isUpdating ? 'input' : 'hidden'}
        />
      </>
      {isUpdating && (
        <Field
          component={SelectInput}
          isDisabled
          isMulti
          label="Platforms"
          name="platforms"
          options={PLATFORM_OPTIONS}
          size="small"
        />
      )}
    </Flex>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, SubHeader, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          {!hideSteps && (
            <SubHeader>
              <Text fontWeight="bold">{stepSubTitles[currentStep]}</Text>
            </SubHeader>
          )}
          <Form
            decorators={[
              setIdentifier
            ]}
            initialValues={{
              technology,
              platforms: [ platform! ],
              dimensions: [],
              summary: `This is a Product for ${currentWorkspace.name} workspace.`,
              ...initialValues
            }}
            keepDirtyOnReinitialize
            onSubmit={handleSubmit}
            validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  {isFirstStep && <FirstStep />}
                  {isFinalStep && (
                  <Flex direction="column" as="form" onSubmit={handleSubmit}>
                    <FinalStep />
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                  )}
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent={hideSteps ? 'flex-end' : 'space-between'}>
                    {!hideSteps && <Chip label={`Step ${currentStep + 1}`} icon="arrow-right" iconPlacement="right" variant="light" />}
                    <Flex gap={16}>
                      {!isFirstStep && !hideSteps && <Button icon="arrow-left" onClick={handleStepBehind} />}
                      {!isFinalStep && (
                        <Button
                          disabled={isFirstStep && !technology}
                          icon="arrow-right"
                          onClick={handleStepForward}
                        />
                      )}
                      {isFinalStep && <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />}
                    </Flex>
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

CreateProductView.defaultStyle = 'PANEL' as const

export default CreateProductView
