import React, { useRef } from 'react'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import uuid from 'uuid-random'
import { array } from 'yup'
import { useField } from 'react-final-form'
import { useFieldArray as useFinalFormFieldArray } from 'react-final-form-arrays'
import type { FieldArrayRenderProps } from 'react-final-form-arrays'
import type { FieldSubscription } from 'final-form'

import arraySwap from 'lib/arraySwap'
import HintBox from 'components/hints/HintBox'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type FieldArrayChildrenProps<T> = {
  meta: FieldArrayRenderProps<T, HTMLElement>['meta'],
  fields: FieldArrayRenderProps<T, HTMLElement>['fields'],
  keys: string[]
}

type FieldArrayProps<T> = {
  name: string,
  shouldValidate?: boolean,
  subscription?: FieldSubscription,
  children: (args: FieldArrayChildrenProps<T>) => React.ReactElement[] | React.ReactElement,
  fieldsRef: React.MutableRefObject<FieldArrayChildrenProps<T> | undefined>
} & ({
  settings: fieldProps<''>['settings'],
  uniqueBy?: T extends object ? keyof T : never
} | {
  settings?: never,
  uniqueBy?: never
})

const validate = <T, >(
  settings: FieldArrayProps<T>['settings'] = {},
  uniqueBy: FieldArrayProps<T>['uniqueBy'],
  value: T[]
) => {
  let schema = array().ensure()
  const { repeatedMaximum, repeatedMinimum, repeatedAllowDuplicates = true } = settings

  if (repeatedMaximum) {
    schema = schema.max(repeatedMaximum)
  }

  if (repeatedMinimum) {
    schema = schema.min(repeatedMinimum)
  }

  if (!repeatedAllowDuplicates) {
    schema = schema
      .test(
        'unique',
        'must have unique items',
        (list: any[]) => {
          if (uniqueBy) { return list.length === uniqBy(list, uniqueBy).length }
          return list.length === uniq(list).length
        }
      )
  }

  try {
    schema.validateSync(value)
  } catch (error: any) {
    return error.message
  }

  return undefined
}

const useFieldArray = <T, >({
  name, settings, shouldValidate, fieldsRef, uniqueBy, subscription
}: Omit<FieldArrayProps<T>, 'children'>) => {
  const { fields, meta } = useFinalFormFieldArray<T>(name, {
    ...(shouldValidate && { validate: (value) => validate<T>(settings, uniqueBy, value) }),
    subscription
  })

  const field = useField(name)

  const keysRef = useRef<string[]>((Array.isArray(fields.value) ? fields.value : []).map(uuid))

  const childrenProps: FieldArrayChildrenProps<T> = {
    keys: keysRef.current,
    meta,
    fields: {
      ...fields,
      // workaround for fields.change undefined
      change: field.input.onChange,
      push: (value) => {
        fields.push(value)
        keysRef.current.push(uuid())
      },
      move: (from, to) => {
        fields.move(from, to)
        arraySwap(keysRef.current!, from, to)
      },
      remove: (index) => {
        keysRef.current.splice(index, 1)
        return fields.remove(index)
      }
    }
  }

  fieldsRef.current = childrenProps

  return childrenProps
}

function FieldArray<T>({ children, ...props }: FieldArrayProps<T>) {
  const childrenProps = useFieldArray<T>(props)

  const { meta } = childrenProps
  // if meta.error is string thats the ARRAY LEVEL message
  // otherwise it gets populated with child field errors as well and becomes an error[]
  const error = typeof meta.error === 'string' && meta.touched && meta.error
  return (
    <>
      {children(childrenProps)}
      {error && <HintBox size="small" variant="error" icon="error">{error}</HintBox>}
    </>
  )
}

FieldArray.getFieldName = (name: string, index?: number) => (index !== undefined ? `${name}[${index}]` : name)

export type { FieldArrayProps, FieldArrayChildrenProps }
export default FieldArray
export { useFieldArray }
