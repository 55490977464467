import React, { ReactNode } from 'react'

import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

type HintBoxProps = {
  alignItems?: FlexProps['alignItems'],
  children: ReactNode,
  headline?: string,
  icon?: string,
  withoutTextWrapper?: boolean
}

const BORDER_RADIUS = 6
const PADDING_NORMAL_X = 35
const PADDING_NORMAL_Y = 25
const PADDING_SMALL_X = 20
const PADDING_SMALL_Y = 10
const PADDING_COMPACT_X = 15
const PADDING_COMPACT_Y = 15

const StyledIcon = styled(Icon, {
  color: 'dark100'
})

const StyledHintBox = styled(Flex, {
  borderRadius: BORDER_RADIUS,

  variants: {
    variant: {
      error: {
        backgroundColor: 'negative500',
        [`& ${StyledIcon}`]: {
          color: 'light100'
        },
        [`& ${Text}`]: {
          color: 'light100'
        }
      },
      info: {
        backgroundColor: 'light500'
      },
      light: {
        backgroundColor: 'light100'
      }
    },
    size: {
      normal: {
        paddingY: PADDING_NORMAL_Y,
        paddingX: PADDING_NORMAL_X
      },
      small: {
        paddingX: PADDING_SMALL_X,
        paddingY: PADDING_SMALL_Y
      },
      compact: {
        paddingX: PADDING_COMPACT_X,
        paddingY: PADDING_COMPACT_Y
      }
    }
  }
})

function HintBox({
  variant = 'info',
  size = 'normal',
  alignItems,
  children,
  headline,
  icon = 'info',
  withoutTextWrapper = false
}: HintBoxProps & StyledProps<typeof StyledHintBox>) {
  const isCompact = size === 'compact'

  return (
    <StyledHintBox
      variant={variant}
      size={size}
      gap={isCompact ? 10 : 20}
      alignItems={alignItems || (headline ? 'flex-start' : 'center')}
    >
      <StyledIcon
        name={icon}
        size={isCompact ? 16 : 22}
      />
      <Flex direction="column" gap={10}>
        {headline && (
          <Text color="dark900" fontWeight="bold" fontSize={18}>
            {headline}
          </Text>
        )}
        {withoutTextWrapper ? (
          children
        ) : (
          <Text color="dark500" lineHeight="cozy" fontSize={14}>
            {children}
          </Text>
        )}
      </Flex>
    </StyledHintBox>
  )
}

export default HintBox
