import React from 'react'

import TitleBlock from 'components/blocks/TitleBlock'
import IntegrationsList from 'components/views/apps/IntegrationsList'

function IntegrationsPage() {
  return (
    <>
      <TitleBlock heading="Integrations" />
      <IntegrationsList />
    </>
  )
}

export default IntegrationsPage
