import React, { useRef, useState } from 'react'

import AppIcon from 'components/icons/AppIcon'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CreateProductView from 'components/views/CreateProductView'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import HintBox from 'components/hints/HintBox'
import MediaCard from 'components/mediaCard/MediaCard'
import SearchBar from 'components/searchbar/SearchBar'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import { Product, useProductsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'

type ProductsProps = {products: Product[]}

type ProductCardsProps = {
  productsList: Product[],
  className?: string
}

const defaultAction = [ {
  description: '',
  icon: 'arrow-right',
  isIconAlwaysVisible: true
} ]

const ProductCards = ({ productsList, className }: ProductCardsProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { openView } = useViewDispatch()

  const renderProductCard = (product: Product) => {
    const { id, icon, identifier, name } = product

    const onEditProduct = () => openView({
      title: 'Edit Product',
      component: CreateProductView,
      params: {
        initialValues: product,
        queryVariables: {
          limit: PRODUCTS_LIST_LIMIT,
          order: [ { name: 'asc' } ]
        }
      },
      style: 'PANEL'
    })

    return (
      <MediaCard
        key={id}
        media={identifier && (
          <AppIcon
            identifier={identifier as any}
            icon={icon}
            size={32}
          />
        )}
        onClick={onEditProduct}
        title={name}
        titlePosition="top"
        text={identifier}
        width="full"
        actions={defaultAction}
      />
    )
  }

  return (
    <Grid
      ref={ref}
      className={className}
      gap={24}
      columns={4}
    >
      {productsList.map(renderProductCard)}
    </Grid>
  )
}

const ProductsGrid = ({ products }: ProductsProps) => (
  <Flex direction="column" gap={36}>
    <Flex
      direction="column"
      gap={14}
    >
      <Flex
        direction="column"
      >

        <ProductCards
          productsList={products}
        />

      </Flex>
      <Divider variant="whitespace" spacing="4" />
    </Flex>
  </Flex>
)

const PRODUCTS_LIST_LIMIT = 100

function ProductsPage() {
  const {
    data: { productsList } = {},
    error,
    loading
  } = useProductsListQuery({
    variables: {
      limit: PRODUCTS_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const [ searchText, setSearchText ] = useState('')
  const { openView } = useViewDispatch()

  const onCreateProduct = () => openView({
    title: 'New Product',
    component: CreateProductView,
    params: {
      initialValues: {},
      queryVariables: {
        limit: PRODUCTS_LIST_LIMIT,
        order: [ { name: 'asc' } ]
      }
    },
    style: 'PANEL'
  })

  const filteredProducts = productsList?.filter(
    (product) => product.name.toLowerCase().includes(searchText.toLowerCase())
  ) || []

  return (
    <>
      <TitleBlock heading="Products" />
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Flex direction="column" gap={36}>
          <HintBox headline="What are Products?">
            <Text as="span" color="inherit" fontWeight="bold">Products</Text> are software components that you wish to integrate with DashX.
            {' '}
            They can range from <Text as="span" color="inherit" fontWeight="bold">marketing websites</Text>, customer facing <Text as="span" color="inherit" fontWeight="bold">web portals</Text>,
            {' '}
            <Text as="span" color="inherit" fontWeight="bold">mobile products</Text>, to even <Text as="span" color="inherit" fontWeight="bold">backend microservices</Text>.
          </HintBox>

          <Flex justifyContent="space-between" gap={16}>
            <SearchBar onChange={(e) => setSearchText(typeof e === 'string' ? e : e.target.value)} placeholder="Search..." css={{ maxWidth: 240 }} />
            <Button
              onClick={onCreateProduct}
              icon="add-thin"
              size="small"
            />
          </Flex>
          <SectionLoader
            empty={{
              variant: 'neutral',
              element: (
                <Flex alignItems="center" direction="column" gap={16}>
                  <Flex alignItems="center" direction="column" gap={8}>
                    <Text fontWeight="bold">There are no products.</Text>
                    <Text fontSize={14}>Nothing to show here.</Text>
                  </Flex>
                  <Button label="Create Product" size="small" mode="distinct" onClick={onCreateProduct} />
                </Flex>
              )
            }}
            data={productsList || []}
            loading={loading}
            error={error}
          >
            <ProductsGrid
              products={filteredProducts}
            />
          </SectionLoader>
        </Flex>
      </Block>
    </>
  )
}

export default ProductsPage
