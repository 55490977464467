import Scrollbars from 'react-custom-scrollbars'
import React, { useState } from 'react'
import type { ChangeEvent, FC } from 'react'

import Button from 'components/buttons/Button'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import Divider from 'components/divider/Divider'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import MediaInput from 'components/inputs/MediaInput'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import useAssetUpload from 'hooks/useAssetUpload'
import { Popover, PopoverBody, PopoverContainer, PopoverFooter, PopoverHeader } from 'components/popover'
import { styled } from 'styles/stitches'
import { getAssetBaseUrl } from 'lib/env'

interface Props {
  onClick: (payload: any) => void
}

const noop = () => {}

const StyledContainer = styled(Flex, {
  paddingX: 24,
  paddingY: 20
})

const REACT_APP_ASSET_BASE_URL = getAssetBaseUrl()

const MediaCommand: FC<Props> = ({ children, onClick }) => {
  const [ url, setUrl ] = useState('')
  const [ altText, setAltText ] = useState('')
  const [ showAltText, setShowAltText ] = useState(false)
  const [ alignment, setAlignment ] = useState('center')
  const [ width, setWidth ] = useState<string>()
  const [ height, setHeight ] = useState<string>()
  const [ mimeType, setMimeType ] = useState('image/*')

  const { upload, isUploading } = useAssetUpload({
    onUploadSuccess: (asset) => {
      const fileUrl = asset?.url || `${REACT_APP_ASSET_BASE_URL}/${asset.data.asset.name}`
      setUrl(fileUrl)
      if (!altText) setAltText(asset?.name || '')
      setMimeType(asset?.mimeType || 'image/*')
    }
  })

  const onSelect = (media: Blob, mediaName: string, index: number) => {
    upload(media, mediaName, { fieldName: 'image', index })
  }

  return (
    <PopoverContainer
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -38, 8 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <div {...otherToggleProps}>
          {children}
        </div>
      )}
      {(popoverProps) => (
        <Popover withArrow {...popoverProps}>
          <PopoverHeader title="Media" withDivider />
          <PopoverBody>
            <Scrollbars autoHeight autoHeightMin="350px">
              <StyledContainer direction="column" gap={8}>
                <FieldLabel>Upload</FieldLabel>
                <MediaInput
                  accept={[ 'image/*', 'video/*' ]}
                  standalone
                  setToUndefinedOnDelete
                  isUploading={isUploading}
                  width={100}
                  height={100}
                  meta={{}}
                  input={{ name: 'image', value: url, onChange: noop, onBlur: noop, onFocus: noop }}
                  onDelete={() => {
                    setUrl('')
                    setAltText('')
                  }}
                  onSelect={onSelect}
                />
                <Divider variant="ruler" />
                <Flex direction="column" gap={8}>
                  <TextInput
                    input={{
                      value: url,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)
                    }}
                    meta={{}}
                    label="Media Url"
                    size="small"
                  />
                  <TextInput
                    input={{
                      value: width,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => setWidth(e.target.value)
                    }}
                    label="Width"
                    size="small"
                  />
                  <TextInput
                    input={{
                      value: height,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => setHeight(e.target.value)
                    }}
                    label="Height"
                    size="small"
                  />
                  <TextInput
                    input={{
                      value: altText,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => (
                        setAltText(e.target.value)
                      )
                    }}
                    label="Caption"
                    size="small"
                  />
                  <ToggleInput
                    meta={{}}
                    label="Show Caption"
                    input={{
                      name: 'showAlt',
                      checked: showAltText,
                      value: '',
                      onChange: (e: ChangeEvent<HTMLInputElement>) => (
                        setShowAltText(e.target.checked)
                      ),
                      onBlur: noop,
                      onFocus: noop
                    }}
                  />
                  <ButtonGroupInput
                    inputLabel="Alignment"
                    size="small"
                    meta={{}}
                    options={[ { label: 'Left', value: 'left' }, { label: 'Center', value: 'center' }, { label: 'Right', value: 'right' } ]}
                    input={{
                      name: 'imageAlignment',
                      onChange: setAlignment,
                      value: alignment,
                      onBlur: noop,
                      onFocus: noop
                    }}
                  />
                </Flex>
              </StyledContainer>
            </Scrollbars>
          </PopoverBody>
          <PopoverFooter>
            <StyledContainer>
              <Button
                onClick={() => {
                  onClick({
                    url,
                    width,
                    height,
                    altText,
                    alignment,
                    showAltText,
                    mimeType
                  })
                  popoverProps.closePopover()
                }}
                label="Add"
                size="small"
              />
            </StyledContainer>
          </PopoverFooter>
        </Popover>
      )}
    </PopoverContainer>
  )
}

export default MediaCommand
