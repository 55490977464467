import calendar from 'dayjs/plugin/calendar'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'

import ConditionalField from 'components/form/ConditionalField'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { DataTypeKind } from 'models/DataType'
import type { Attribute, CreateAttributeInput, UpdateAttributeInput } from 'generated/schema'
import { PlainTextRenderer } from './PlainTextView'

dayjs.extend(localizedFormat)
dayjs.extend(calendar)

type FormValues = CreateAttributeInput | UpdateAttributeInput

type ConfigurationsProps = {
  fieldPrefix?: string,
  dataType: DataTypeKind
}

enum Format {
  CALENDAR_TIME = 'calendar_time',
  RELATIVE_TIME = 'relative_time',
  PRESET = 'preset',
  CUSTOM = 'custom'
}

const FormatOptions = [
  { label: 'Calendar Time', value: Format.CALENDAR_TIME },
  { label: 'Relative time', value: Format.RELATIVE_TIME },
  { label: 'Preset', value: Format.PRESET },
  { label: 'Custom', value: Format.CUSTOM }
]

enum PresetKind {
  TIME = 'TIME',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME'
}

const PresetOptions = [
  { label: 'h:mm A', description: '8:02 PM', value: 'LT', kind: PresetKind.TIME },
  { label: 'h:mm:ss A', description: '8:02:18 PM', value: 'LTS', kind: PresetKind.TIME },
  { label: 'MM/DD/YYYY', description: '08/16/2018', value: 'L', kind: PresetKind.DATE },
  { label: 'M/D/YYYY', description: '8/16/2018', value: 'l', kind: PresetKind.DATE },
  { label: 'MMM D, YYYY', description: 'Aug 16, 2018', value: 'll', kind: PresetKind.DATE },
  { label: 'MMMM D, YYYY', description: 'August 16, 2018', value: 'LL', kind: PresetKind.DATE },
  { label: 'MMM D, YYYY h:mm A', description: 'Aug 16, 2018 8:02 PM', value: 'lll', kind: PresetKind.DATE_TIME },
  { label: 'MMMM D. YYYY h:mm A', description: 'August 16, 2018 8:02 PM', value: 'LLL', kind: PresetKind.DATE_TIME },
  { label: 'ddd, MMM D, YYYY h:mm A', description: 'Thu, Aug 16, 2018 8:02 PM', value: 'llll', kind: PresetKind.DATE_TIME },
  { label: 'dddd, MMMM D. YYYY h:mm A', description: 'Thursday, August 16, 2018 8:02 PM', value: 'LLLL', kind: PresetKind.DATE_TIME }
]

const PrecisionOptions = [
  { label: 'Second', value: 'second' },
  { label: 'Minute', value: 'minute' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
  { label: 'Decade', value: 'decade' }
]

const DEFAULT_FORMAT = 'lll'

const parseDateTimeValues = (values: FormValues) => {
  const { displayTypeSettings } = values
  const { format, preset = '', custom = '', relative = {} } = displayTypeSettings || {}

  switch (format) {
    case Format.CALENDAR_TIME:
      return { ...values, displayTypeSettings: { format: Format.CALENDAR_TIME } }

    case Format.RELATIVE_TIME:
      return { ...values, displayTypeSettings: { format: { [Format.RELATIVE_TIME]: relative } } }

    case Format.PRESET:
      return { ...values, displayTypeSettings: { format: { [Format.PRESET]: preset } } }

    case Format.CUSTOM:
      return { ...values, displayTypeSettings: { format: { [Format.CUSTOM]: custom } } }

    default:
      return values
  }
}

const formatDateTimeValues = (values: FormValues) => {
  const { displayTypeSettings } = values
  const { format } = displayTypeSettings || {}

  if (typeof format === 'string') return values

  if (typeof format === 'object') {
    const relative = format[Format.RELATIVE_TIME]
    const preset = format[Format.PRESET]
    const custom = format[Format.CUSTOM]

    if (relative) {
      return { ...values, displayTypeSettings: { format: Format.RELATIVE_TIME, relative } }
    }

    if (preset) {
      return { ...values, displayTypeSettings: { format: Format.PRESET, preset } }
    }

    if (custom) {
      return { ...values, displayTypeSettings: { format: Format.CUSTOM, custom } }
    }
  }

  return values
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Blank = () => (
  <Text fontSize={14} fontWeight="bold">-</Text>
)

const useDateTimeRenderer = ({ displaySettings, data }: RendererProps) => {
  const { format, preset } = displaySettings || {}

  if (!data) return '-'

  const parsedDate = data ? data.split('+')[0] : data

  // First try parsing as a Date/Time
  let day = dayjs(parsedDate)

  if (!day.isValid()) {
    // Try parsing as ISO Time
    day = dayjs(parsedDate, 'hh:mm:ss')
  }

  if (parsedDate?.length > 10) {
    day = day.utc(true).local()
  }

  let date = dayjs().to(day)
  date = day.format(DEFAULT_FORMAT)

  if (format) {
    if (typeof format === 'string') {
      if (format === Format.CALENDAR_TIME) {
        date = dayjs().calendar(day)
      } else {
        date = day.format(preset)
      }
    }

    if (typeof format === 'object') {
      const preset = format[Format.PRESET]
      const custom = format[Format.CUSTOM]

      if (preset) date = day.format(preset)
      if (custom) date = day.format(custom)
    }
  }

  return date
}

const DateTimeRenderer = ({ displaySettings, data }: RendererProps) => {
  const date = useDateTimeRenderer({ displaySettings, data })

  if (!data) {
    return <Blank />
  }

  return (
    <PlainTextRenderer displaySettings={displaySettings} data={date} />
  )
}

const Configurations = ({
  fieldPrefix = 'displayTypeSettings.',
  dataType
}: ConfigurationsProps) => {
  const presetOptions = PresetOptions.filter(
    (presetOption) => {
      if (dataType === DataTypeKind.TIME) {
        return presetOption.kind === PresetKind.TIME
      } if (dataType === DataTypeKind.DATE) {
        return presetOption.kind === PresetKind.DATE
      }

      return true
    }
  )

  return (
    <Grid alignItems="center" gap={16}>
      <FormField
        name={`${fieldPrefix}format`}
        component={SelectInput}
        label="Format"
        size="small"
        options={FormatOptions}
      />
      <ConditionalField when={`${fieldPrefix}format`} is={Format.RELATIVE_TIME}>
        <FormField
          checkRequired
          name={`${fieldPrefix}relative.min_precision`}
          label="Minimum Precision"
          component={SelectInput}
          options={PrecisionOptions}
          size="small"
        />
        <FormField
          checkRequired
          name={`${fieldPrefix}relative.max_precision`}
          label="Maximum Precision"
          component={SelectInput}
          options={PrecisionOptions}
          size="small"
        />
      </ConditionalField>
      <ConditionalField when={`${fieldPrefix}format`} is={Format.PRESET}>
        <FormField
          checkRequired
          name={`${fieldPrefix}preset`}
          component={SelectInput}
          label="Preset Style"
          size="small"
          options={presetOptions}
        />
      </ConditionalField>
      <ConditionalField when={`${fieldPrefix}format`} is={Format.CUSTOM}>
        <FormField
          checkRequired
          name={`${fieldPrefix}custom`}
          label="Custom pattern"
          component={TextInput}
          size="small"
        />
      </ConditionalField>
    </Grid>
  )
}

export default {
  Configurations
}

export {
  DateTimeRenderer,
  formatDateTimeValues,
  parseDateTimeValues,
  useDateTimeRenderer
}
