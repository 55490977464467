const getApiBaseUrl = () => {
  const { REACT_APP_API_BASE_URL } = process.env

  return localStorage.getItem('apiBaseUrl') || REACT_APP_API_BASE_URL
}

const getAssetBaseUrl = () => {
  const { REACT_APP_ASSET_BASE_URL } = process.env

  return localStorage.getItem('assetBaseUrl') || REACT_APP_ASSET_BASE_URL
}

export { getApiBaseUrl, getAssetBaseUrl }
