import classNames from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import type { Dispatch, KeyboardEvent, ReactNode, SetStateAction } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import scrollIntoViewIfNeeded from 'lib/scrollIntoViewIfNeeded'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'

type PopoverProps = {
  arrow?: ReactNode,
  autoFocus?: boolean,
  children?: ReactNode,
  className?: string,
  closePopover?: () => void,
  referenceElement?: HTMLElement | null,
  withArrow?: boolean,
  style?: any
}

type PopoverContextProps = {
  closePopover?: () => void,
  isNested: boolean,
  setIsNested: Dispatch<SetStateAction<boolean>>
}

const POPOVER_BACKGROUND_COLOR = 'light100'
const POPOVER_BORDER_RADIUS = 4

const StyledPopover = styled('div', {
  ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

  backgroundColor: POPOVER_BACKGROUND_COLOR,
  borderRadius: POPOVER_BORDER_RADIUS,
  zIndex: 'popover'
})

const PopoverContext = createContext<PopoverContextProps | null>(null)

const usePopoverContext = () => {
  const state = useContext(PopoverContext)

  if (!state) {
    throw new Error('`usePopoverContext` must be used inside `Popover` component')
  }

  return state
}

const useNestedPopover = () => {
  const [ isNested, setIsNested ] = useState(false)

  const state = useContext(PopoverContext)

  useEffect(() => {
    if (!state?.isNested) {
      state?.setIsNested(true)
    }

    return () => {
      if (state?.isNested) {
        state?.setIsNested(false)
      }
    }
  }, [ state ])

  return [ isNested, setIsNested ] as const
}

const SEARCHABLE_KEYS = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '{', '}', '[', ']', '|', ':', ';', '"', '\'', '<', '>', ',', '.', '?', '/', '\\', '~', '`' ]

const TEXT_ATTRIBUTE = 'data-text'
const LABEL_ATTRIBUTE = 'data-label'

const rotateFocus = (container: HTMLElement, query: string) => {
  const elements = Array.from(container?.querySelectorAll(query) as NodeListOf<HTMLElement>)

  const textFound = elements.some((el, index) => {
    const isNotCurrentlyActive = document.activeElement !== el
    const isPreceedingElementActive = elements[index - 1] === document.activeElement
    const isLastElementActive = elements[elements.length - 1] === document.activeElement

    if (isNotCurrentlyActive && (isPreceedingElementActive || isLastElementActive)) {
      el.focus()
      return true
    }

    return false
  })

  if (!textFound) {
    elements.some((el) => {
      const isNotCurrentlyActive = document.activeElement !== el

      if (isNotCurrentlyActive) {
        el.focus()
        return true
      }

      return false
    })
  }

  return !!elements?.length
}

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(({
  arrow,
  autoFocus = false,
  children,
  className,
  closePopover,
  referenceElement,
  withArrow = false,
  ...props
}, ref) => {
  const menuItemsContainerRef = useRef<HTMLDivElement>(null)
  const [ searchTerm, setSearchTerm ] = useState('')

  const [ isNested, setIsNested ] = useNestedPopover()

  useEffect(() => {
    if (autoFocus) {
      const firstMenuItem = menuItemsContainerRef.current?.querySelector('[role="menuitem"]') as HTMLElement | null
      const menuItemsContainer = menuItemsContainerRef.current as HTMLElement | null
      (firstMenuItem || menuItemsContainer)?.focus()
    }
  }, [ autoFocus ])

  useEffect(() => {
    const id = setTimeout(() => setSearchTerm(''), 3000)

    return () => clearTimeout(id)
  }, [ searchTerm ])

  const handleKeyDown = (event: KeyboardEvent) => {
    const focusedEl = document.activeElement
    const menuItemsContainer = menuItemsContainerRef.current as HTMLElement | null
    const firstMenuItem = menuItemsContainerRef.current?.querySelector('[role="menuitem"]') as HTMLElement | null
    const lastMenuItem = menuItemsContainerRef.current?.querySelector('[role="menuitem"]:last-child') as HTMLElement | null
    const firstEl = firstMenuItem || menuItemsContainer?.firstElementChild as HTMLElement
    const lastEl = lastMenuItem || menuItemsContainer?.lastElementChild as HTMLElement

    let targetEl = null
    const { key } = event
    const isSearchable = SEARCHABLE_KEYS.includes(key.toLocaleLowerCase())

    switch (key) {
      case 'Escape':
        closePopover?.()
        break
      case 'ArrowDown':
        event.preventDefault()
        if (focusedEl) {
          if (menuItemsContainer?.contains(focusedEl)) {
            let nextEl = focusedEl?.nextElementSibling as HTMLElement
            while (nextEl && nextEl.getAttribute('role') !== 'menuitem') {
              nextEl = nextEl.nextElementSibling as HTMLElement
            }
            if (nextEl) {
              targetEl = nextEl
              break
            }
          }
        }

        targetEl = firstEl
        break

      case 'ArrowUp':
        event.preventDefault()
        if (focusedEl) {
          if (menuItemsContainer?.contains(focusedEl)) {
            let prevEl = focusedEl?.previousElementSibling as HTMLElement
            while (prevEl && prevEl.getAttribute('role') !== 'menuitem') {
              prevEl = prevEl.previousElementSibling as HTMLElement
            }
            if (prevEl) {
              targetEl = prevEl
              break
            }
          }
        }

        targetEl = lastEl
        break

      default:
        // Typeahed search logic
        setSearchTerm((s) => {
          if (key === 'Backspace') {
            return s.slice(0, -1)
          }

          if (isSearchable) {
            const newSearchTerm = `${s}${key.toLocaleLowerCase()}`
            const firstMatchingTextElement = menuItemsContainer?.querySelector(`button[${TEXT_ATTRIBUTE}^="${newSearchTerm}"]`) as HTMLElement
            firstMatchingTextElement?.focus()

            if (!firstMatchingTextElement) {
              const firstMatchingLabelElement = menuItemsContainer?.querySelector(`button[${LABEL_ATTRIBUTE}^="${newSearchTerm}"]`) as HTMLElement
              firstMatchingLabelElement?.focus()

              if (!firstMatchingLabelElement) {
                const rotated = menuItemsContainer && rotateFocus(menuItemsContainer, `button[${TEXT_ATTRIBUTE}^="${newSearchTerm[newSearchTerm.length - 1]}"]`)

                if (!rotated) {
                  menuItemsContainer && rotateFocus(menuItemsContainer, `button[${LABEL_ATTRIBUTE}^="${newSearchTerm[newSearchTerm.length - 1]}"]`)
                }
              }
            }

            return newSearchTerm
          }

          return s
        })
    }

    targetEl && scrollIntoViewIfNeeded(targetEl)
    targetEl?.focus()
  }

  return (
    <OutsideClickHandler
      disabled={isNested}
      onOutsideClick={(e) => {
        if (referenceElement?.contains?.(e.target as HTMLElement)) return
        closePopover?.()
      }}
    >
      <PopoverContext.Provider value={{ closePopover, isNested, setIsNested }}>
        <StyledPopover
          className={classNames(
            className
          )}
          ref={ref}
          {...props}
        >
          {withArrow ? arrow : <div style={{ display: 'none' }}>{arrow}</div>}
          <Flex
            direction="column"
            onKeyDown={handleKeyDown}
            ref={menuItemsContainerRef}
            role="menu"
            tabIndex={-1}
          >
            {children}
          </Flex>
        </StyledPopover>
      </PopoverContext.Provider>
    </OutsideClickHandler>
  )
})

Popover.displayName = 'Popover'

export default Popover

export {
  LABEL_ATTRIBUTE,
  POPOVER_BORDER_RADIUS,
  TEXT_ATTRIBUTE,
  usePopoverContext,
  useNestedPopover
}

export type { PopoverProps }
