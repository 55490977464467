import React from 'react'
import { useLocation } from 'react-router-dom'

import Block from 'components/blocks/Block'
import ExtensionsView from 'components/views/apps/ExtensionsView'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import IntegrationsList from 'components/views/apps/IntegrationsList'
import TitleBlock from 'components/blocks/TitleBlock'
import ProjectsView from 'components/views/apps/ProjectsView'

const HASH_TO_INDEX_MAP = {
  '#projects': 0,
  '#extensions': 1,
  '#integrations': 2
}

function AppsPage() {
  const location = useLocation()
  return (
    <>
      <TitleBlock heading="Apps" />
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Tabs
            // @ts-ignore
          initialIndex={HASH_TO_INDEX_MAP[location.hash] || 0}
        >
          <Tab href="#projects" index={HASH_TO_INDEX_MAP['#projects']} label="Projects">
            <ProjectsView />
          </Tab>
          <Tab href="#extensions" index={HASH_TO_INDEX_MAP['#extensions']} label="Extensions">
            <ExtensionsView />
          </Tab>
          <Tab href="#integrations" index={HASH_TO_INDEX_MAP['#integrations']} label="Integrations">
            <IntegrationsList />
          </Tab>
        </Tabs>
      </Block>
    </>
  )
}

export default AppsPage
