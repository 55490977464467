import compact from 'lodash/compact'
import React, { useMemo } from 'react'
import { defaultDataIdFromObject } from '@apollo/client'

import AddConfigurationView from 'components/views/AddConfigurationView'
import Button from 'components/buttons/Button'
import client from 'client'
import CopyToClipboard from 'components/buttons/CopyToClipboard'
import DataList, { RendererOptions } from 'components/dataList/DataList'
import Divider from 'components/divider/Divider'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InstallAppView from 'components/views/InstallAppView'
import Label from 'components/typography/Label'
import PageLoader from 'components/loaders/PageLoader'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { getAppIcon } from 'models/App'
import { css } from 'styles/stitches'
import { Configuration, ConfigurationsListDocument, Environment, EnvironmentsListDocument, Installation, InstallationFragmentFragmentDoc, useConfigurationsListQuery, useConfigureInstallationMutation, useCreateConfigurationMutation, useDestroyConfigurationMutation, useEnvironmentsListQuery, useInstallationQuery } from 'generated/schema'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { Status as ConfigurationStatus, STATUS_COLOR, STATUS_ICON, STATUS_MESSAGE } from 'models/Configuration'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'
import type { InstallationQuery } from 'generated/schema'
import type { ViewProps } from 'components/views'
import { getApiBaseUrl } from 'lib/env'

type ViewParamsType = {
  installationId: string
}

type ConfigurationDrawerProps = {
  canConfigure: boolean,
  configuration: Configuration,
  openEditConfigurationView: (configuration: Configuration) => void,
  onDisableConfiguration: (configuration: Configuration) => void,
  onTryAgain: (configuration: Configuration) => void,
  onRefreshStatus: () => void,
  webhookUrlPrefix: false | string
}

const REACT_APP_API_BASE_URL = getApiBaseUrl()

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

const ConfigurationDrawer = ({
  canConfigure,
  configuration,
  openEditConfigurationView,
  onDisableConfiguration,
  onTryAgain,
  onRefreshStatus,
  webhookUrlPrefix
}: ConfigurationDrawerProps) => (
  <DrawerBlock
    as={Flex}
    icon="environment"
    headerPadding="small"
    title={configuration.environment.name}
    drawerAction={(
      <Flex css={{ color: STATUS_COLOR[configuration.status] }} alignItems="center">
        <Icon size={12} color="dark200" name={STATUS_ICON[configuration.status]} />
      </Flex>
      )}
  >
    {() => (
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={10} grow={1}>
          <Label>Status</Label>
          <Flex css={{ color: STATUS_COLOR[configuration.status] }} alignItems="center" gap={8}>
            <Icon name={STATUS_ICON[configuration.status]} size={16} />
            <Text fontSize={14} color="dark800">
              {STATUS_MESSAGE[configuration.status]}
            </Text>
          </Flex>
          {configuration.statusMessage && <Text fontSize={14} color="dark600">{configuration.statusMessage}</Text>}
        </Flex>
        {webhookUrlPrefix && (
          <Flex direction="column" gap={10} grow={1}>
            <Label>Webhook</Label>
            <TextInput
              appendNode={<CopyToClipboard textToCopy={`${webhookUrlPrefix}/${configuration.environmentId}/${configuration.digest}`} />}
              helpText="Use this URL to send events"
              size="small"
              value={`${webhookUrlPrefix}/${configuration.environmentId}/${configuration.digest}`}
            />
          </Flex>
        )}
        <Flex gap={10} grow={1}>
          {canConfigure && (
            <Button
              mode="distinct"
              variant="outline"
              size="small"
              label="Configure"
              onClick={() => openEditConfigurationView(configuration)}
            />
          )}
          {configuration.status === ConfigurationStatus.FAILED
              && <Button label="Try Again" variant="outline" mode="distinct" size="small" onClick={() => onTryAgain(configuration)} />}
          {configuration.status === ConfigurationStatus.PENDING
              && <Button label="Refresh Status" variant="outline" mode="distinct" size="small" onClick={() => onRefreshStatus()} />}
          <Button
            mode="distinct"
            variant="outline"
            size="small"
            label="Disable"
            onClick={() => onDisableConfiguration(configuration)}
          />
        </Flex>
      </Flex>
    )}
  </DrawerBlock>
)

const Overview = (
  { installation }: { installation: Installation }
) => {
  const { openView } = useViewDispatch()
  const canConfigure = installation.app.fields.length > 0

  const {
    data,
    loading,
    error
  } = useEnvironmentsListQuery()

  const configurationsListQueryVariables = {
    filter: {
      installationId: { eq: installation.id }
    }
  }

  const {
    data: configurationsData,
    loading: configurationsLoading,
    error: configurationsError,
    refetch
  } = useConfigurationsListQuery({
    variables: configurationsListQueryVariables
  })

  const configurationsList = configurationsData?.configurationsList
  const environmentsList = data?.environmentsList

  const openCreateConfigurationView = (environmentId: string) => {
    openView({
      title: 'New Configuration',
      component: AddConfigurationView,
      params: {
        initialValues: {
          installationId: installation.id,
          environmentId,
          settings: {}
        },
        app: installation.app,
        configurationsListQueryVariables
      },
      style: 'PANEL'
    })
  }

  const unConfiguredEnvironmentsList = useMemo(() => compact((environmentsList || []).map(
    (environment) => ((configurationsList || []).findIndex(
      (configuration) => configuration.environmentId === environment.id
    ) === -1 ? environment : null)
  )), [ configurationsList, environmentsList ])

  const [ createConfiguration ] = useCreateConfigurationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EnvironmentsListDocument },
      { query: ConfigurationsListDocument, variables: configurationsListQueryVariables }
    ]
  })

  const handleEnabling = useSubmitHandler(createConfiguration)

  const onEnable = (environmentId: string) => handleEnabling({
    installationId: installation.id,
    environmentId,
    settings: {}
  })

  const isCustomApp = !!installation.app.workspaceId

  const { hasWebhooks } = isCustomApp
    ? { hasWebhooks: false }
    : installation.app.meta

  const webhookUrlPrefix = hasWebhooks && `${REACT_APP_API_BASE_URL}/webhooks/${installation.id}`

  const openEditConfigurationView = (configuration: Configuration) => {
    openView({
      title: 'Edit Configuration',
      component: AddConfigurationView,
      params: {
        initialValues: {
          id: configuration.id,
          settings: configuration.settings
        },
        app: installation.app
      },
      style: 'PANEL'
    })
  }

  const [ destroyConfiguration ] = useDestroyConfigurationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EnvironmentsListDocument },
      { query: ConfigurationsListDocument, variables: configurationsListQueryVariables }
    ]
  })

  const handleDestroyConfiguration = useSubmitHandler(destroyConfiguration)

  const onDisableConfiguration = (configuration: Configuration) => (
    handleDestroyConfiguration({ id: configuration.id })
  )

  const [ configureInstallation ] = useConfigureInstallationMutation({
    refetchQueries: [
      { query: ConfigurationsListDocument }
    ]
  })
  const handleConfigureInstallation = useSubmitHandler(configureInstallation)

  const onTryAgain = (configuration: Configuration) => {
    handleConfigureInstallation({ id: configuration.id })
  }

  const contents: Content<Environment>[] = [
    { dataKey: 'name', slot: 'icon', renderer: () => <Icon name="environment" size={16} /> },
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'id',
      slot: 'meta',
      renderer: ({ rowData }: RendererOptions<Environment>) => (
        <Button
          mode="subtle"
          variant="outline"
          size="small"
          label={canConfigure ? 'Configure' : 'Enable'}
          onClick={() => (canConfigure
            ? openCreateConfigurationView(rowData.id)
            : onEnable(rowData.id)
          )}
        />
      ) }
  ]

  return (
    <>
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Configurations</Text>
        {!configurationsData ? (
          <PageLoader
            data={configurationsData}
            loading={configurationsLoading}
            error={configurationsError}
          />
        ) : (
          <>
            {configurationsList?.map((configuration) => (
              <ConfigurationDrawer
                canConfigure={canConfigure}
                configuration={configuration as Configuration}
                webhookUrlPrefix={webhookUrlPrefix}
                openEditConfigurationView={openEditConfigurationView}
                onDisableConfiguration={onDisableConfiguration}
                onTryAgain={onTryAgain}
                onRefreshStatus={refetch}
              />
            ))}
            {unConfiguredEnvironmentsList.length > 0 && (
              <>
                <Label>Not Configured</Label>
                <DataList
                  actions={[]}
                  contents={contents}
                  data={unConfiguredEnvironmentsList as any[]}
                  loading={loading}
                  error={error}
                  selectionMode="none"
                  paginationMode="none"
                  dataListItemVariant="detailed"
                />
              </>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

const Actions = ({ installation }: { installation: Installation }) => {
  const { openView } = useViewDispatch()

  const openInstallationForm = (installation: Installation) => {
    openView({
      title: `${installation.app.name}`,
      component: InstallAppView,
      params: {
        selectedApp: installation.app,
        initialValues: installation,
        queryVariables: { id: installation.id }
      },
      style: 'PANEL'
    })
  }
  const onModifySettings = () => openInstallationForm(installation)

  return (
    <Flex direction="column" gap={12}>
      <Text fontWeight="bold">Actions</Text>
      <Button label="Modify Settings" variant="outline" mode="distinct" size="small" onClick={onModifySettings} />
      <Button label="View Documentation" variant="outline" mode="distinct" size="small" rel="noopener noreferrer" target="_blank" href={`https://docs.dashx.com/platform/integrations/${installation.app.identifier}`} />
    </Flex>
  )
}

// const About = ({ app }: { app: Installation['app'] }) => (
//   <Flex direction="column" gap={36}>
//     <MarkdownRenderer source={app.summary!} />
//   </Flex>
// )

function IntegrationView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { installationId } = params
  const cachedInstallationData = useMemo(() => {
    const installation = client.readFragment<InstallationQuery['installation'], {}>({
      id: defaultDataIdFromObject({ __typename: 'Installation', _id: installationId }),
      fragment: InstallationFragmentFragmentDoc,
      fragmentName: 'InstallationFragment'
    })
    if (!installation) return null

    return { installation }
  }, [ installationId ])

  const {
    data = cachedInstallationData,
    loading,
    error
  } = useInstallationQuery({
    variables: { id: installationId }, skip: !installationId
  })

  const title = data?.installation.name || 'App'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title="" onCloseClick={onRequestClose}>
            <Flex alignItems="stretch" grow={1} justifyContent="space-between">
              <Flex alignItems="center" gap={20}>
                {data?.installation.app && (
                  <>
                    <img
                      style={{ width: '32px' }}
                      src={getAppIcon(data?.installation.app.identifier)}
                      alt={data?.installation.app.identifier || ''}
                    />
                    <Divider orientation="vertical" />
                    <Flex direction="column" gap={8}>
                      <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                        {data?.installation.app.name}
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>
          </Header>
          {!data ? (
            <Body>
              <PageLoader data={data} loading={loading} error={error} />
            </Body>
          ) : (
            <Tabs
              tabListBackground="light"
              panelWrapper={Body}
              tabListClassName={classes.tabsList}
            >
              <Tab label="Overview" index={0}>
                <Flex direction="column" gap={36}>
                  <Overview installation={data.installation as Installation} />
                  <Actions installation={data.installation as Installation} />
                </Flex>
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </View>
  )
}

IntegrationView.defaultStyle = 'PANEL' as const

export { Overview }

export default IntegrationView
