import copy from 'copy-text-to-clipboard'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import * as mixins from 'styles/mixins'
import AddResourceView from '../AddResourceView'
import AttributesList from './AttributesList'
import Button from 'components/buttons/Button'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import HintBox from 'components/hints/HintBox'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import OperationsList from './OperationsList'
import RelationshipsList from './RelationshipsList'
import SearchBar from 'components/searchbar/SearchBar'
import SegmentsList from './SegmentsList'
import Text from 'components/typography/Text'
import Toolbar from 'components/toolbar/Toolbar'
import Tooltip from 'components/tooltip/Tooltip'
import useFuzzySearch from 'hooks/useFuzzySearch'
import usePager from 'hooks/usePager'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { App, Resource, ResourcesListDocument, useResourcesAggregateQuery, useResourcesListQuery, useUpdateResourceMutation } from 'generated/schema'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'
import type { SelectionHandlerProps } from 'components/providers/DataManagerProvider'

const CHIP_BORDER_RADIUS = 13
const CHIP_HEIGHT = 26
const CHIP_X_PADDING = 10

const StyledToolbar = styled(Flex, {
  marginY: 10,

  '& > div > div > [data-icon]': {
    color: 'dark900'
  }
})

const CustomChip = styled(Flex, {
  ...mixins.transition('simple'),

  cursor: 'pointer',
  width: 'fit-content',
  height: CHIP_HEIGHT,
  borderRadius: CHIP_BORDER_RADIUS,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingX: CHIP_X_PADDING,
  paddingY: 0,
  backgroundColor: 'dark100',
  gap: 8,

  [`& > ${Text}`]: {
    color: 'dark700',
    fontSize: 11,
    fontFamily: 'code',
    fontWeight: 600
  },

  '& > [data-copy-icon]': {
    ...mixins.transition('simple'),

    color: 'dark500'
  },

  '&:hover': {

    '& > [data-copy-icon]': {
      color: 'dark700'
    }
  }
})

type ResourcesListProps = { app: App, workspaceId?: undefined } | { app: null, workspaceId: string }

const order = [ {
  position: 'asc'
} ]

const ResourcesList = ({ app, workspaceId }: ResourcesListProps) => {
  const appId = app?.id
  const selectionHandlerRef = useRef<SelectionHandlerProps>(null)
  const { openView } = useViewDispatch()

  const [ page, limit, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 20
  })

  const filter = !workspaceId
    ? {
      appId: { eq: appId }
    } : {
      appId: null,
      workspaceId: { eq: workspaceId }
    }

  const skip = !appId && !workspaceId

  const queryVariables = {
    filter,
    order,
    page,
    limit
  }

  const {
    data: { resourcesList = [] } = {}, loading, error
  } = useResourcesListQuery({
    variables: queryVariables,
    skip
  })

  const {
    data: { resourcesAggregate } = {}
  } = useResourcesAggregateQuery({
    variables: {
      filter
    },
    skip
  })

  const [ isCopied, setIsCopied ] = useState(false)
  const handleMouseLeave = () => setIsCopied(false)

  const handleClick = () => {
    if (selectedResource) {
      copy(selectedResource.identifier)
      setIsCopied(true)
    }
  }

  const tooltip = isCopied ? 'Copied!' : 'Copy Resource Identifier'

  const { matches, handleChange } = useFuzzySearch<Resource>(resourcesList as Resource[], { key: 'name' })

  const [ selectedResourceId, setSelectedResourceId ] = useState<Resource['id'] | null>(null)
  const selectedResource = useMemo(() => resourcesList.find(
    (resource) => selectedResourceId === resource.id
  ), [ selectedResourceId, resourcesList ])

  const [ updateResource ] = useUpdateResourceMutation()

  const handleUpdateResource = useSubmitHandler(updateResource)

  const reorder = useReorder({
    query: ResourcesListDocument,
    variables: queryVariables,
    dataKey: 'resourcesList',
    callback: handleUpdateResource
  })

  const onAdd = () => openView({
    title: 'New Resource',
    component: AddResourceView,
    params: {
      appId,
      initialValues: {
        name: '',
        identifier: '',
        appId,
        position: generatePosition(getMaxPosition(resourcesList)),
        environmentBehavior: 'NONE'
      },
      onComplete: (resource: Resource) => {
        setSelectedResourceId(resource.id)
        selectionHandlerRef.current?.selectRow(resource.id)
      },
      queryVariables
    },
    style: AddResourceView.defaultStyle
  })

  const onEdit = (resource: Resource) => openView({
    title: 'Edit Resource',
    component: AddResourceView,
    params: {
      appId,
      initialValues: resource,
      queryVariables
    },
    style: AddResourceView.defaultStyle
  })

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' }
  ]

  useEffect(() => {
    const [ selectedId ] = selectionHandlerRef.current?.selection || []
    const [ firstResource ] = resourcesList

    if (!firstResource) {
      return
    }

    if (!selectedId || !resourcesList.some(({ id }) => id === selectedId)) {
      selectionHandlerRef.current?.selectRow(firstResource.id)
      setSelectedResourceId(firstResource.id)
    }
  }, [ resourcesList, page ])

  const isSystemApp = app && !app.workspaceId

  return (
    <Flex gap={36}>
      <Flex direction="column" gap={16} css={{ width: '25%' }}>
        <Toolbar
          title="Resources"
          secondaryElements={!isSystemApp && (
            <>
              <Button icon="add-thin" size="small" onClick={onAdd} />
            </>
          )}
        />
        <SearchBar placeholder="Search resources" onChange={handleChange} />
        <DataList
          actions={[]}
          contents={contents}
          data={matches}
          loading={loading}
          error={error}
          selectionHandlerRef={selectionHandlerRef}
          onRowSelect={
            (resource, isSelected) => {
              if (isSelected) {
                setSelectedResourceId(resource.id)
              }
            }
          }
          {...(!selectedResource?.isReadOnly && !isSystemApp && { onRowDragEnd: reorder })}
          selectionMode="single"
          dataListItemVariant="detailed"
          page={page}
          pageSize={limit}
          paginationMode="finite"
          onChangePage={handlePageChange}
          onChangePageSize={handlePageSizeChange}
          totalRows={resourcesAggregate?.count || 0}
          withPageControls={false}
        />
      </Flex>

      {selectedResource ? (
        <Flex direction="column" alignSelf="stretch" gap={36} css={{ width: '75%' }}>
          <StyledToolbar>
            <Flex alignItems="center" gap={20} grow={1}>
              <Flex alignItems="center" gap={8}>
                <Icon data-icon name={selectedResource.icon || 'graph'} />
                <Text fontWeight="bold" fontSize={18}>{selectedResource.name}</Text>
              </Flex>
              <Tooltip description={tooltip}>
                <CustomChip onMouseLeave={handleMouseLeave} onClick={handleClick}>
                  <Text>{selectedResource.identifier}</Text>
                  <Icon
                    data-copy-icon
                    name="copy"
                    size={10}
                  />
                </CustomChip>
              </Tooltip>
            </Flex>
            <Flex alignItems="center" gap={12} shrink={1}>
              {!selectedResource.isReadOnly && !isSystemApp && (
                <IconButton
                  name="edit"
                  description="Edit"
                  onClick={() => onEdit(selectedResource as Resource)}
                  variant="darker"
                />
              )}
            </Flex>
          </StyledToolbar>
          <AttributesList resource={selectedResource as Resource} />
          <RelationshipsList resource={selectedResource as Resource} />
          <SegmentsList resource={selectedResource as Resource} />
          <OperationsList resource={selectedResource as Resource} appId={appId} />
        </Flex>
      ) : (
        <Flex direction="column" grow={1} css={{ width: '75%' }}>
          <HintBox>
            Select a resource to view Attributes, Relationships and Operations.
          </HintBox>
        </Flex>
      )}
    </Flex>
  )
}
export default ResourcesList
