import React from 'react'

import { useParams } from 'react-router-dom'

import Block from 'components/blocks/Block'
import PageLoader from 'components/loaders/PageLoader'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import { Graph } from 'components/views/InstallationView'
import { App, useAppQuery } from 'generated/schema'

function AppSchemaPage() {
  const queryParams = useQueryParams()
  const { appId } = useParams<{ appId: string }>()
  const id = queryParams.get('id') || appId

  const {
    data: { app } = {},
    loading,
    error
  } = useAppQuery({
    variables: {
      id
    }
  })

  return (
    <PageLoader
      data={app}
      loading={loading}
      error={error}
    >
      <TitleBlock
        heading="Data"
      />
      <Block direction="column" gap={36} width={{ md: '100%' }}>
        <Graph app={app as App} />
      </Block>
    </PageLoader>
  )
}

export default AppSchemaPage
