import React from 'react'

import Block from 'components/blocks/Block'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import Grid from 'components/layout/Grid'

const defaultAction = [ {
  description: '',
  icon: 'arrow-right',
  isIconAlwaysVisible: true
} ]

function OverviewPage() {
  return (
    <>
      <TitleBlock heading="Welcome to your Workspace!" />
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Flex direction="column" gap={24}>
          <Text fontWeight="bold">Manage Your Apps</Text>
          <Grid columns={3} gap={24}>
            <MediaCard
              titlePosition="top"
              width="full"
              title="Projects"
              text="Build internal and external business apps"
              media="projects"
              to="/~workspace/apps#projects"
              actions={defaultAction}
            />
            <MediaCard
              titlePosition="top"
              width="full"
              title="Extensions"
              text="Extend your workspace with our pre-built apps"
              media="apps"
              to="/~workspace/apps#extensions"
              actions={defaultAction}
            />
            <MediaCard
              titlePosition="top"
              width="full"
              title="Integrations"
              text="Install third-party apps to add more functionality"
              media="integration"
              to="/~workspace/apps#integrations"
              actions={defaultAction}
            />
          </Grid>
        </Flex>
        <Flex direction="column" gap={24}>
          <Text fontWeight="bold">Customize Your Workspace</Text>
          <Grid columns={3} gap={24}>
            <MediaCard
              titlePosition="top"
              width="full"
              title="Invite Team Members"
              text="Add members to your workspace"
              media="account"
              to="/~workspace/accounts"
              actions={defaultAction}
            />
            <MediaCard
              titlePosition="top"
              width="full"
              title="Theme & Branding"
              text="Bring your brand's own look-and-feel"
              media="theme"
              to="/~workspace/settings"
              actions={defaultAction}
            />
            <MediaCard
              titlePosition="top"
              width="full"
              title="Dashboards"
              text="Visualize your data with powerful dashboards"
              media="dashboard"
              to="/~workspace/dashboards"
              actions={defaultAction}
            />
          </Grid>
        </Flex>
        {/* <Flex direction="column" gap={24}>
          <Text fontWeight="bold">Learn More</Text>
          <Grid columns={3} gap={24}>
            <MediaCard
            titlePosition="top"
              width="full"
              title="Explore Docs"
              text="Learn more about DashX"
              media="page-stroked"
              href="https://docs.dashx.com"
              actions={defaultAction}
            />
            <MediaCard
            titlePosition="top"
              width="full"
              title="Join our Community"
              text="Connect with us on Slack for any assistance"
              media="group"
              href="https://join.slack.com/t/dashxhq/shared_invite/zt-2w097l2n4-FLjuUBLSknnno_5LOBBZaQ"
              actions={defaultAction}
            />
          </Grid>
        </Flex> */}
      </Block>
    </>
  )
}

export default OverviewPage
