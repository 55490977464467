import React, { useRef, useState } from 'react'

import AppIcon from 'components/icons/AppIcon'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CreateSpaceView from 'components/views/CreateSpaceView'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import HintBox from 'components/hints/HintBox'
import MediaCard from 'components/mediaCard/MediaCard'
import SearchBar from 'components/searchbar/SearchBar'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import { Space, useSpacesListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'

type SpacesProps = {spaces: Space[]}

type SpaceCardsProps = {
  spacesList: Space[],
  className?: string
}

const defaultAction = [ {
  description: '',
  icon: 'arrow-right',
  isIconAlwaysVisible: true
} ]

const lockedAction = [ {
  description: '',
  icon: 'pad-lock',
  isIconAlwaysVisible: true
} ]

const SpaceCards = ({ spacesList, className }: SpaceCardsProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { openView } = useViewDispatch()

  const renderSpaceCard = (space: Space) => {
    const { id, icon, identifier, name } = space

    const onEditSpace = () => openView({
      title: 'Edit Space',
      component: CreateSpaceView,
      params: {
        initialValues: space,
        queryVariables: {
          limit: PRODUCTS_LIST_LIMIT,
          order: [ { name: 'asc' } ]
        }
      },
      style: 'PANEL'
    })

    return (
      <MediaCard
        key={id}
        media={identifier && (
          <AppIcon
            identifier={identifier as any}
            icon={icon}
            size={32}
          />
        )}
        onClick={onEditSpace}
        title={name}
        titlePosition="top"
        width="full"
        actions={defaultAction}
      />
    )
  }

  return (
    <Grid
      ref={ref}
      className={className}
      gap={24}
      columns={4}
    >
      {spacesList.map(renderSpaceCard)}
    </Grid>
  )
}

const SpacesGrid = ({ spaces }: SpacesProps) => (
  <Flex direction="column" gap={36}>
    <Flex
      direction="column"
      gap={14}
    >
      <Flex
        direction="column"
      >

        <SpaceCards
          spacesList={spaces}
        />

      </Flex>
    </Flex>
  </Flex>
)

const PRODUCTS_LIST_LIMIT = 100

function SpacesPage() {
  const {
    data: { spacesList } = {},
    error,
    loading
  } = useSpacesListQuery({
    variables: {
      limit: PRODUCTS_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const [ searchText, setSearchText ] = useState('')
  const { openView } = useViewDispatch()

  const onCreateSpace = () => openView({
    title: 'New Space',
    component: CreateSpaceView,
    params: {
      initialValues: {},
      queryVariables: {
        limit: PRODUCTS_LIST_LIMIT,
        order: [ { name: 'asc' } ]
      }
    },
    style: 'PANEL'
  })

  const filteredSpaces = spacesList?.filter(
    (space) => space.name.toLowerCase().includes(searchText.toLowerCase())
  ) || []

  return (
    <>
      <TitleBlock heading="Spaces" />
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Flex direction="column" gap={36}>
          <HintBox headline="What are Spaces?" variant="info">
            <Text as="span" color="inherit" fontWeight="bold">Spaces</Text> help isolate data within certain Apps and provide access to specific teams of people within your company.
            {' '}For example, you can create a space for Sales, Marketing, Operations, Support, Engineering and so on.
          </HintBox>

          <Flex justifyContent="space-between" gap={16}>
            <SearchBar onChange={(e) => setSearchText(typeof e === 'string' ? e : e.target.value)} placeholder="Search..." css={{ maxWidth: 240 }} />
            <Button
              onClick={onCreateSpace}
              icon="add-thin"
              size="small"
            />
          </Flex>
          <SectionLoader
            empty={{
              variant: 'neutral',
              element: (
                <Flex alignItems="center" direction="column" gap={16}>
                  <Flex alignItems="center" direction="column" gap={8}>
                    <Text fontWeight="bold">There are no spaces.</Text>
                    <Text fontSize={14}>Nothing to show here.</Text>
                  </Flex>
                  <Button label="Create Space" size="small" mode="distinct" onClick={onCreateSpace} />
                </Flex>
              )
            }}
            data={spacesList || []}
            loading={loading}
            error={error}
          >
            <SpacesGrid
              spaces={filteredSpaces}
            />
          </SectionLoader>
          <Text fontWeight="bold">System Spaces</Text>
          <Grid columns={4} gap={24}>
            <MediaCard
              media="globe"
              title="Workspace"
              titlePosition="top"
              width="full"
              actions={lockedAction}
            />
            <Text css={{ 'grid-column-start': 'span 3', alignSelf: 'center' }}>
              By default your Workspace forms your largest Space, and is accessible by anyone with a <Text as="span" fontWeight="bold">Workspace Member</Text> role, login access to workspace.
            </Text>
          </Grid>
          <Grid columns={4} gap={24}>
            <MediaCard
              media="individual"
              title="Personal"
              titlePosition="top"
              width="full"
              actions={lockedAction}
            />
            <Text css={{ 'grid-column-start': 'span 3', alignSelf: 'center' }}>
              Each Workspace Member has their own private Space, accessible to only them, as
              {' '}long as they have login access to the workspace.
            </Text>
          </Grid>
        </Flex>
      </Block>
    </>
  )
}

export default SpacesPage
