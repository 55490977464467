import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Block from 'components/blocks/Block'
import Flex from 'components/layout/Flex'
import PageLoader from 'components/loaders/PageLoader'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import { About } from 'components/views/InstallationView'
import { Installation, useAppQuery, useInstallationsListQuery } from 'generated/schema'

function AppPage() {
  const queryParams = useQueryParams()

  const { appId } = useParams<{ appId: string }>()
  const id = queryParams.get('id') || appId
  const queryVariables = {
    filter: {
      appId: { eq: id }
    }
  }

  const {
    data: { app } = {},
    loading,
    error
  } = useAppQuery({
    variables: {
      id
    }
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsListLoading,
    error: installationsListError
  } = useInstallationsListQuery({
    variables: queryVariables
  })

  const installation: Installation | undefined = useMemo(
    () => (installationsList as Installation[]).find((inst) => inst.appId === id),
    [ id, installationsList ]
  )

  return (
    <PageLoader
      data={app}
      loading={loading || installationsListLoading}
      error={error || installationsListError}
    >
      <TitleBlock
        heading={app?.name}
      />
      <Block direction="column" gap={36} width={{ md: '100%' }}>
        {app && (
          <Flex direction="column" gap="18">
            <Text fontWeight="bold">About this App</Text>
            <About
              app={app}
              installation={installation}
            />
          </Flex>
        )}
      </Block>
    </PageLoader>
  )
}

export default AppPage
