import { useContext } from 'react'
import { getViews } from 'dashx-fixtures'
import type { ApolloError } from '@apollo/client'

import DashboardContext from 'components/contexts/DashboardContext'
import parseViewUrn from './parseViewUrn'
import { View, useResolveViewQuery, useAppQuery, useResourceQuery } from 'generated/schema'
import { APP_IDS } from 'models/App'

type Result = {
  data?: View,
  loading?: boolean,
  error?: ApolloError
}

const useViewUrn = ({ urn }: {urn?: string} = {}): Result => {
  const {
    selectedSideMenuElement,
    selectedTopMenuElement
  } = useContext(DashboardContext) || {}

  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement
  const selectedViewUrn = urn || selectedMenuElement?.viewUrn
  const urnObject = parseViewUrn(selectedViewUrn)

  const views = getViews()
  const view = views[urnObject?.componentPath || '']

  const { data, loading, error } = useResolveViewQuery({
    variables: {
      input: {
        urn: selectedViewUrn!
      }
    },
    // skip if urn is not defined or if urn is of type resource or app
    skip: !selectedViewUrn || urnObject?.type === 'app'
  })

  const appId = view?.app && APP_IDS[view.app as keyof typeof APP_IDS]
  const { data: appData, loading: appLoading, error: appError } = useAppQuery({
    variables: {
      id: appId
    },
    skip: !appId
  })

  const { data: resourceData, loading: resourceLoading, error: resourceError } = useResourceQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: urnObject?.id!
    },
    skip: urnObject?.type !== 'resource'
  })

  if (view) {
    if (urnObject?.type === 'resource' && data?.resolveView) {
      return {
        data: data?.resolveView as View,
        loading,
        error
      }
    }

    if (urnObject?.type === 'resource' && loading) {
      return {
        loading,
        error
      }
    }

    if (urnObject?.type === 'resource' && !data?.resolveView) {
      return {
        data: ({
          ...view,
          resourceId: urnObject.id,
          resource: resourceData?.resource
        } as unknown) as View,
        loading: false
      }
    }

    if (data?.resolveView) {
      return {
        data: data.resolveView as View,
        loading: false
      }
    }

    return {
      data: ({
        ...view,
        appId: appId || undefined,
        app: appData?.app
      } as unknown) as View,
      loading: false
    }
  }

  return {
    data: data?.resolveView as View,
    loading: loading || appLoading || resourceLoading,
    error: error || appError || resourceError
  }
}

export default useViewUrn
