import React, { useRef, useState } from 'react'

import IconButton from './IconButton'
import { css } from 'styles/stitches'
import type { IconButtonProps } from './IconButton'

const START = 0
const END = -180
const rotate = css.keyframes({
  from: {
    transform: `rotate(${START})`
  },

  to: {
    transform: `rotate(${END}deg)`
  }
})

function RefreshButton({ onClick, ...props }: Partial<IconButtonProps>) {
  const [ loading, setLoading ] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  const stopLoading = () => {
    // @ts-ignore
    const [ transforms ] = ref.current?.computedStyleMap?.().getAll('transform') || []
    const transform = transforms?.[0]
    if (!transform || transform.angle.value >= (START - 1) || transform.angle.value <= (END + 1)) {
      setLoading(false)
      return
    }

    requestAnimationFrame(stopLoading)
  }

  const handleRefresh = async (event: any) => {
    setLoading(true)
    await onClick?.(event)
    requestAnimationFrame(stopLoading)
  }

  return (
    <IconButton
      {...props}
      css={{
        animation: loading ? `1s infinite forwards ${rotate}` : undefined
      }}
      disabled={loading}
      hideTooltip={loading}
      onClick={handleRefresh}
      ref={ref}
    />
  )
}

export default RefreshButton
