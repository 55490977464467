import compact from 'lodash/compact'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { ViewParams, Views } from '../constants'
import Button from 'components/buttons/Button'
import CopyToClipboard from 'components/buttons/CopyToClipboard'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataList, { RendererOptions, Content } from 'components/dataList/DataList'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Label from 'components/typography/Label'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Configuration, ConfigurationsListDocument, Environment, EnvironmentsListDocument, Installation, useConfigurationsListQuery, useConfigureInstallationMutation, useCreateConfigurationMutation, useDestroyConfigurationMutation, useEnvironmentsListQuery } from 'generated/schema'
import { Status as ConfigurationStatus, STATUS_COLOR, STATUS_ICON, STATUS_MESSAGE } from 'models/Configuration'
import type { ActiveViewProps } from '../DashboardEditor'
import { getApiBaseUrl } from 'lib/env'

type ConfigurationDrawerProps = {
  configuration: Configuration,
  onDisableConfiguration: (configuration: Configuration) => void,
  onTryAgain: (configuration: Configuration) => void,
  onRefreshStatus: () => void,
  webhookUrlPrefix: false | string
}

type Params = ViewParams[Views.INTEGRATION_DETAILS]

const REACT_APP_API_BASE_URL = getApiBaseUrl()

const ConfigurationDrawer = ({
  configuration,
  onDisableConfiguration,
  onTryAgain,
  onRefreshStatus,
  webhookUrlPrefix
}: ConfigurationDrawerProps) => (
  <DrawerBlock
    as={Flex}
    icon="environment"
    headerPadding="small"
    title={configuration.environment.name}
    drawerAction={(
      <Flex css={{ color: STATUS_COLOR[configuration.status] }} alignItems="center">
        <Icon size={12} color="dark200" name={STATUS_ICON[configuration.status]} />
      </Flex>
      )}
  >
    {() => (
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={10} grow={1}>
          <Label>Status</Label>
          <Flex css={{ color: STATUS_COLOR[configuration.status] }} alignItems="center" gap={8}>
            <Icon name={STATUS_ICON[configuration.status]} size={16} />
            <Text fontSize={14} color="dark800">
              {STATUS_MESSAGE[configuration.status]}
            </Text>
          </Flex>
          {configuration.statusMessage && <Text fontSize={14} color="dark600">{configuration.statusMessage}</Text>}
        </Flex>
        {webhookUrlPrefix && (
          <Flex direction="column" gap={10} grow={1}>
            <Label>Webhook</Label>
            <TextInput
              appendNode={<CopyToClipboard textToCopy={`${webhookUrlPrefix}/${configuration.environmentId}/${configuration.digest}`} />}
              helpText="Use this URL to send events"
              size="small"
              value={`${webhookUrlPrefix}/${configuration.environmentId}/${configuration.digest}`}
            />
          </Flex>
        )}
        <Flex gap={10} grow={1}>
          {configuration.status === ConfigurationStatus.FAILED
              && <Button label="Try Again" variant="outline" mode="distinct" size="small" onClick={() => onTryAgain(configuration)} />}
          {configuration.status === ConfigurationStatus.PENDING
              && <Button label="Refresh Status" variant="outline" mode="distinct" size="small" onClick={() => onRefreshStatus()} />}
          <Button
            mode="distinct"
            variant="outline"
            size="small"
            label="Disable"
            onClick={() => onDisableConfiguration(configuration)}
          />
        </Flex>
      </Flex>
    )}
  </DrawerBlock>
)

const Overview = (
  { installation }: { installation: Installation }
) => {
  const canConfigure = installation.app.fields.length > 0

  const {
    data,
    loading,
    error
  } = useEnvironmentsListQuery()

  const configurationsListQueryVariables = {
    filter: {
      installationId: { eq: installation.id }
    }
  }

  const {
    data: configurationsData,
    loading: configurationsLoading,
    error: configurationsError,
    refetch
  } = useConfigurationsListQuery({
    variables: configurationsListQueryVariables
  })

  const configurationsList = configurationsData?.configurationsList
  const environmentsList = data?.environmentsList

  const unConfiguredEnvironmentsList = useMemo(() => compact((environmentsList || []).map(
    (environment) => ((configurationsList || []).findIndex(
      (configuration) => configuration.environmentId === environment.id
    ) === -1 ? environment : null)
  )), [ configurationsList, environmentsList ])

  const [ createConfiguration ] = useCreateConfigurationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EnvironmentsListDocument },
      { query: ConfigurationsListDocument, variables: configurationsListQueryVariables }
    ]
  })

  const handleEnabling = useSubmitHandler(createConfiguration)

  const onEnable = (environmentId: string) => handleEnabling({
    installationId: installation.id,
    environmentId,
    settings: {}
  })

  const isCustomApp = !!installation.app.workspaceId

  const { hasWebhooks } = isCustomApp
    ? { hasWebhooks: false }
    : installation.app.meta

  const webhookUrlPrefix = hasWebhooks && `${REACT_APP_API_BASE_URL}/webhooks/${installation.id}`

  const [ destroyConfiguration ] = useDestroyConfigurationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EnvironmentsListDocument },
      { query: ConfigurationsListDocument, variables: configurationsListQueryVariables }
    ]
  })

  const handleDestroyConfiguration = useSubmitHandler(destroyConfiguration)

  const onDisableConfiguration = (configuration: Configuration) => (
    handleDestroyConfiguration({ id: configuration.id })
  )

  const [ configureInstallation ] = useConfigureInstallationMutation({
    refetchQueries: [
      { query: ConfigurationsListDocument }
    ]
  })
  const handleConfigureInstallation = useSubmitHandler(configureInstallation)

  const onTryAgain = (configuration: Configuration) => {
    handleConfigureInstallation({ id: configuration.id })
  }

  const contents: Content<Environment>[] = [
    { dataKey: 'name', slot: 'icon', renderer: () => <Icon name="environment" size={16} /> },
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'id',
      slot: 'meta',
      renderer: ({ rowData }: RendererOptions<Environment>) => (canConfigure
        ? <Text fontSize={10} textTransform="uppercase">Not Configured</Text>
        : (
          <Button
            mode="subtle"
            variant="outline"
            size="small"
            label="Enable"
            onClick={() => onEnable(rowData.id)}
          />
        )) }
  ]

  return (
    <>
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Configurations</Text>
        {!configurationsData ? (
          <PageLoader
            data={configurationsData}
            loading={configurationsLoading}
            error={configurationsError}
          />
        ) : (
          <>
            {configurationsList?.map((configuration) => (
              <ConfigurationDrawer
                key={configuration.id}
                configuration={configuration as Configuration}
                webhookUrlPrefix={webhookUrlPrefix}
                onDisableConfiguration={onDisableConfiguration}
                onTryAgain={onTryAgain}
                onRefreshStatus={refetch}
              />
            ))}
            {unConfiguredEnvironmentsList.length > 0 && (
              <>
                <Label>Not Configured</Label>
                <DataList
                  actions={[]}
                  contents={contents}
                  data={unConfiguredEnvironmentsList as any[]}
                  loading={loading}
                  error={error}
                  selectionMode="none"
                  paginationMode="none"
                  dataListItemVariant="detailed"
                />
              </>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

const IntegrationDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { integration: installation } = params as Params

  return (
    <>
      <DashboardEditorHeader
        heading="Integrations"
        subtitle={installation.name}
        onClose={onClose}
      />
      <DashboardEditorBody>
        <Overview installation={installation} />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const Actions = () => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { integration: installation } = params as Params

  const onClick = () => openDashboardEditorView({
    target: Views.ADD_INTEGRATION_FORM,
    params: {
      ...params,
      initialValues: {
        selectedApp: installation.app
      }
    }
  })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <MediaCard
        compact
        onClick={onClick}
        media="settings"
        title="Modify Settings"
        height={64}
        width="full"
        actions={[ {
          description: '',
          icon: 'arrow-right',
          onClick,
          isIconAlwaysVisible: true
        } ]}
      />
      <MediaCard
        compact
        href={`https://docs.dashx.com/platform/integrations/${installation.app.identifier}`}
        media="documents"
        title="View Documentation"
        height={64}
        width="full"
        actions={[ {
          description: '',
          icon: 'external-link',
          onClick,
          isIconAlwaysVisible: true
        } ]}
      />
    </Flex>
  )
}

export default IntegrationDetailsView
