/* eslint-disable react/no-array-index-key */
import composeRefs from '@seznam/compose-react-refs'
import rafSchd from 'raf-schd'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-use-gesture'
import { useDroppable } from '@dnd-kit/core'
import { useRecoilValue } from 'recoil'
import { useRect } from '@reach/rect'

import * as mixins from 'styles/mixins'
import Block from './Block'
import Box from 'components/layout/Box'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { AddBlockButton, BlockComponent } from 'components/pages/DashboardPage'
import { styled } from 'styles/stitches'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { useMasonryContext } from 'components/layout/Masonry'
import type { BlockProps } from './Block'

type ColumnType = {
  width: string,
  children: any[]
}

type ColumnBlockProps = BlockProps & {
  blockRef?: React.RefCallback<HTMLElement>,
  columns: ColumnType[]
}

const ResizeHandle = styled('div', {
  ...mixins.transition('simple', 'opacity'),

  borderRadius: 4,
  backgroundColor: 'dark900',
  width: 8,
  height: '-webkit-fill-available',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'col-resize',
  opacity: 0,
  top: 32,
  bottom: 32,

  'div:hover > &': {
    opacity: 0.5
  }

})

const { round } = Math

const updateDimensions = rafSchd((
  previousColumnElement,
  nextColumnElement,
  previousColumnWidthWithMovement,
  nextColumnWidthWithMovement
) => {
  previousColumnElement.style.width = `${round(previousColumnWidthWithMovement)}%`
  nextColumnElement.style.width = `${round(nextColumnWidthWithMovement)}%`
})

const Dropzone = ({ id, containerId }: any) => {
  const { draggingOverBlockIdState, draggedBlockState } = useDashboard()
  const draggingOverBlockId = useRecoilValue(draggingOverBlockIdState)
  const draggedBlock = useRecoilValue(draggedBlockState)

  const { setNodeRef, isOver } = useDroppable({
    id: `${id}__dropzone`,
    data: {
      sortable: {
        containerId
      }
    }
  })

  const isVisible = (isOver || draggingOverBlockId === id) && !!draggedBlock

  return (
    <Flex
      ref={setNodeRef}
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: isVisible ? 1 : 0
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'light100'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const Column = ({ id, items, resizeHandle, ...props }: any) => {
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRect(ref)
  const innerWidth = rect?.width

  const [ visible, setVisible ] = useState(false)

  useEffect(() => {
    setVisible(true)

    const timeoutId = setTimeout(() => {
      setVisible(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [ innerWidth ])

  const { setNodeRef } = useDroppable({
    id
  })

  return (
    <Flex
      ref={composeRefs(setNodeRef, ref)}
      direction="column"
      grow={0}
      shrink={0}
      {...props}
    >
      {ref.current?.style?.width && (
        <Flex css={{
          position: 'absolute',
          top: 10,
          right: 10,
          opacity: visible ? 0.9 : 0,
          backgroundColor: 'dark700',
          padding: '2px 4px',
          zIndex: 1,
          ...mixins.transition('simple')
        } as any}
        >
          <Text color="light100" fontSize={12}>
            {ref.current?.style.width}
          </Text>
        </Flex>
      )}
      {items?.map((block: any, index: number) => (
        <Fragment key={block.id}>
          <BlockComponent
            containerId={id}
            id={block.id}
            key={index}
          />
          <Dropzone containerId={id} id={block.id} index={index} />
        </Fragment>
      ))}
      {resizeHandle}
    </Flex>
  )
}

const ResizeHandleContainer = ({ containerRef, columns, onResize, index, id }: any) => {
  const { gap } = useMasonryContext()

  const bind = useDrag((props) => {
    const { down, tap, movement: [ mx ], _dragTarget } = props

    if (!_dragTarget) return

    if (!('dataset' in _dragTarget)) return

    // @ts-ignore
    const previousColumnIndex = Number(_dragTarget.dataset!.index)
    const nextColumnIndex = previousColumnIndex + 1
    const blockWidth = containerRef?.current?.offsetWidth

    if (!blockWidth) return

    const mxPercentage = (mx / blockWidth) * 100

    const previousColumnElement = containerRef?.current?.children[previousColumnIndex]
    const nextColumnElement = containerRef?.current?.children[nextColumnIndex]

    const previousColumnWidthPercentage = columns[previousColumnIndex]?.width ? Number(columns[previousColumnIndex].width.replace('%', '')) : 100 / columns.length
    const nextColumnWidthPercentage = columns[nextColumnIndex]?.width ? Number(columns[nextColumnIndex].width.replace('%', '')) : 100 / columns.length

    const previousColumnWidthWithMovement = previousColumnWidthPercentage + mxPercentage
    const nextColumnWidthWithMovement = nextColumnWidthPercentage - mxPercentage

    if (previousColumnWidthWithMovement < 10) {
      return
    }

    if (nextColumnWidthWithMovement < 10) {
      return
    }

    if (
      (nextColumnWidthWithMovement + previousColumnWidthWithMovement)
      > (nextColumnWidthPercentage + previousColumnWidthPercentage)
    ) {
      return
    }

    // Dragging
    if (down && !tap) {
      updateDimensions(
        previousColumnElement,
        nextColumnElement,
        previousColumnWidthWithMovement,
        nextColumnWidthWithMovement
      )
    }

    // Dragging ended
    if (!down && !tap) {
      onResize?.([
        ...columns.slice(0, previousColumnIndex),
        {
          ...columns[previousColumnIndex],
          width: `${round(previousColumnWidthWithMovement)}%`
        },
        {
          ...columns[nextColumnIndex],
          width: `${round(nextColumnWidthWithMovement)}%`
        },
        ...columns.slice(nextColumnIndex + 1)
      ]).then(() => {
        previousColumnElement.style.width = ''
        nextColumnElement.style.width = ''
      })
    }
  }, { filterTaps: true, axis: 'x', pointer: { capture: false } })

  const { setNodeRef, isOver } = useDroppable({
    id: `blockId:${id}::${index}`
  })

  const { draggingOverBlockIdState, draggedBlockState } = useDashboard()
  const draggingOverBlockId = useRecoilValue(draggingOverBlockIdState)
  const draggedBlock = useRecoilValue(draggedBlockState)

  const isVisible = (isOver || draggingOverBlockId === id) && !!draggedBlock

  return (
    <Box
      {...bind()}
      data-index={index}
      css={{
        position: 'absolute',
        right: -gap / 2,
        width: gap,
        top: 0,
        bottom: 0,
        zIndex: 1
      } as any}
    >
      <Flex
        ref={setNodeRef}
        justifyContent="center"
        css={{
          position: 'relative' as const,
          color: 'dark700',
          height: '100%',
          width: '100%',
          opacity: isVisible ? 1 : 0
        }}
      >
        <Flex
          css={{
            height: '100%',
            borderRight: '2px dashed dark700',
            '& > [data-icon]': {
              position: 'absolute' as const,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: 6,
              backgroundColor: 'light100',
              overflow: 'hidden'
            }
          }}
        >
          <Icon
            data-icon
            name="add-outline-round"
            size={16}
          />
        </Flex>
      </Flex>
      <ResizeHandle data-index={index} />
    </Box>
  )
}

function ColumnsBlock(
  { columns = [], onResize, containerId, blockRef, ...other }: ColumnBlockProps
) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { updateBlock } = useDashboard()
  const { activeUrn } = useDashboardViewContext()
  const { editMode } = useContext(DashboardContext)!

  useEffect(() => {
    columns?.map((column) => (
      column.children.map((block: any) => (
        updateBlock(activeUrn, block)
      ))
    ))
  }, [ updateBlock, columns, activeUrn ])

  if (!columns?.length) {
    return (
      <AddBlockButton
        alwaysVisible
        label="Add Column"
        {...other}
        id={`blockId:${other.id}::0`}
      />
    )
  }

  return (
    <Block
      css={{ paddingX: !containerId ? 64 : undefined }}
      masonryItemRef={blockRef}
      fullWidth={!containerId}
      direction="column"
      {...other}
    >
      <Flex ref={containerRef}>
        {columns.map((column, index) => (
          <Column
            key={index}
            id={`${other.id}::${index}`}
            items={column.children}
            css={{
              width: column.width || `${100 / columns.length}%`,
              position: 'relative' as any
            }}
            resizeHandle={index < columns.length - 1 && editMode && (
              <ResizeHandleContainer
                id={other.id}
                index={index}
                containerRef={containerRef}
                columns={columns}
                onResize={onResize}
              />
            )}
          />
        ))}
      </Flex>
    </Block>
  )
}

export default ColumnsBlock

export { Column }

export type { ColumnType }
