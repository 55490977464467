import React, { useContext, useRef } from 'react'

import AppCard from 'components/mediaCard/AppCard'
import AppIcon from 'components/icons/AppIcon'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import HintBox from 'components/hints/HintBox'
import PageLoader from 'components/loaders/PageLoader'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import withOnMount from 'hoc/withOnMount'
import { App, AppCategory, useAppCategoriesListQuery, useAppListQuery, useInstallationsListQuery } from 'generated/schema'
import { APP_CATEGORIES_ID, APP_LIST_LIMIT, CUSTOM_CATEGORY_ID } from 'models/App'
import { TourGuideContext } from 'components/providers/TourProvider'
import type { AppIconIdentifier } from 'components/icons/AppIcon'

type AppCardsProps = {
  appList: App[],
  className?: string,
  installationIds: readonly string[]
}

const AppCards = ({ appList, className, installationIds }: AppCardsProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { endTour } = useContext(TourGuideContext)!

  const renderAppCard = (app: App) => {
    const { id, icon, identifier, name, workspaceId, kind } = app
    const isCustomApp = !!workspaceId

    const isInstalled = installationIds.includes(id)
    const prefix = kind === 'PROJECT' ? '/~workspace/project' : '/~workspace/app'

    return (
      <AppCard
        key={id}
        media={identifier && (
          <AppIcon
            isCustomApp={isCustomApp}
            identifier={identifier as AppIconIdentifier}
            icon={icon}
            size={32}
          />
        )}
        to={`${prefix}/${app.id}`}
        target="_self"
        {...(isInstalled && ({
          secondaryIcon: 'accept'
        }))}
        title={name}
        titlePosition="top"
        width="full"
      />
    )
  }

  return (
    <Grid
      ref={ref}
      className={className}
      gap={24}
      columns={4}
      onClick={() => endTour()}
    >
      {appList.map(renderAppCard)}
    </Grid>
  )
}

const AppCardsWithOnMount = withOnMount(AppCards)

function ExtensionsView() {
  const { showNextStep } = useContext(TourGuideContext)!

  const {
    data: { installationsList = [] } = {},
    error: installationsListError,
    loading: installationsListLoading
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        or: [
          { appKind: { eq: 'EXTENSION' } }
        ]
      }
    }
  })

  const {
    data: { appCategoriesList = [] } = {},
    error: appCategoriesListError,
    loading: appCategoriesListLoading
  } = useAppCategoriesListQuery({
    variables: {
      filter: {
        kind: { eq: 'EXTENSION' }
      },
      limit: APP_LIST_LIMIT,
      order: [ { position: 'asc' } ]
    }
  })

  const installedAppIds = installationsList.map(({ appId }) => appId)

  const {
    data: { appsList = [] } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: 'EXTENSION' } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const renderAppCategory = (appCategory: AppCategory) => {
    const isCustomCategory = appCategory.id === CUSTOM_CATEGORY_ID

    const filteredApps = appsList.filter((app) => app.appCategoryId === appCategory.id)

    if (!filteredApps.length) return null

    const [ installedApps, uninstalledApps ] = filteredApps.reduce((acc, app) => {
      acc[installedAppIds.includes(app.id) ? 0 : 1].push(app)
      return acc
    }, [ [], [] ] as [ App[], App[] ])

    return (
      <>
        <Flex justifyContent="space-between">
          <Flex direction="column" gap={16}>
            <Text
              color="dark900"
              fontSize={24}
              fontWeight="bold"
              letterSpacing="compact"
            >
              {appCategory.name} Apps
            </Text>
            {appCategory.description && (
              <Text color="dark500" fontSize={14}>
                {appCategory.description}
                {isCustomCategory && (
                  <>
                    {' '}
                    <TextLink href="https://docs.dashx.com/platform/apps/custom-apps">Learn more</TextLink>
                  </>
                )}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          direction="column"
          gap={14}
        >
          {isCustomCategory && installedApps.length === 0 && (
            <HintBox>
              You don&apos;t have any custom apps installed.
            </HintBox>
          )}
          <Flex
            direction="column"
            {...(appCategory.id === APP_CATEGORIES_ID.Product && { className: 'tg--apps-container' })}
          >
            {!!installedApps.length && (
              <AppCardsWithOnMount
                appList={installedApps}
                installationIds={installedAppIds}
                {...(appCategory.id === APP_CATEGORIES_ID.Product && { onMount: showNextStep })}
              />
            )}
            {installedApps.length > 0
              && uninstalledApps.length > 0
              && <Divider spacing={16} />}
            {!!uninstalledApps.length && (
              <AppCards
                appList={uninstalledApps}
                installationIds={installedAppIds}
              />
            )}
          </Flex>
          <Divider variant="whitespace" spacing="4" />
        </Flex>
      </>
    )
  }

  const data = installationsList && appsList && appCategoriesList
  const loading = installationsListLoading || appsListLoading || appCategoriesListLoading
  const error = installationsListError || appsListError || appCategoriesListError

  return (
    <Flex direction="column" gap={36}>
      <PageLoader data={data} loading={loading} error={error}>
        {appCategoriesList.map(renderAppCategory)}
      </PageLoader>
    </Flex>
  )
}

export { AppCards }

export default ExtensionsView
