import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'

import parseError from 'lib/parseError'
import { alertVar } from 'client/state/alert'
import { defaultQueries, resolvers, typePolicies } from 'client/state'
import { PROSPECT_QUERY } from 'client/state/prospect'
import { SESSION_QUERY } from 'client/state/session'
import { ENVIRONMENT_SWITCHER_QUERY } from 'client/state/environmentSwitcher'
import type { ProspectQuery } from 'client/state/prospect'
import type { SessionQuery } from 'client/state/session'
import type { EnvironmentSwitcherQuery } from 'client/state/environmentSwitcher'
import { getApiBaseUrl } from 'lib/env'

const MAX_RETRIES = 3

const cache = new InMemoryCache({
  typePolicies
})

const writeDefaultsToCache = () => {
  defaultQueries.forEach((defaultQuery) => cache.writeQuery(defaultQuery))

  return Promise.resolve()
}

const retryLink = new RetryLink({
  attempts: {
    max: MAX_RETRIES,
    retryIf: (error, operation) => !!error && operation.getContext()?.shouldRetry
  }
})

// To promote Graphql Errors for being caught by RetryLink
const graphqlErrorLink = new ApolloLink((operation, forward) => {
  if (!operation.getContext()?.shouldRetry) {
    return forward(operation)
  }

  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      const promotedError = new Error()
      promotedError.name = 'PromotedError'

      throw promotedError
    }

    return data
  })
})

const authLink = setContext((_gqlContext, { headers }) => {
  const { session } = cache.readQuery({ query: SESSION_QUERY }) as SessionQuery
  const { prospect } = cache.readQuery({ query: PROSPECT_QUERY }) as ProspectQuery

  const modifiedHeaders = { ...headers }

  if (session?.token) {
    modifiedHeaders['X-Account-Token'] = session.token
  }

  if (prospect?.token) {
    modifiedHeaders['X-Prospect-Token'] = prospect.token
  }

  return { headers: modifiedHeaders }
})

const environmentLink = new ApolloLink((operation, forward) => {
  const switcherData = cache.readQuery({
    query: ENVIRONMENT_SWITCHER_QUERY
  }) as EnvironmentSwitcherQuery
  const { variables } = operation
  const {
    environmentId,
    environmentIdentifier
  } = operation.getContext()

  const environmentTarget = environmentId
    || variables?.filter?.environmentId?.eq
    || variables?.environmentId
    || environmentIdentifier
    || switcherData?.environmentSwitcher?.environment?.id

  if (environmentTarget) {
    operation.setContext(({ headers }: any) => ({
      headers: {
        ...headers,
        'X-Target-Environment': environmentTarget
      }
    }))
  }

  return forward(operation)
})

const errorLink = onError((error) => {
  const { graphQLErrors } = error
  if (!graphQLErrors) {
    return
  }
  const errorCodes = graphQLErrors.map((err) => err.extensions?.code)

  // // TODO: ignore FORBIDDEN errors until fixed on the backend
  // if (errorCodes.includes('FORBIDDEN') && response) {
  //   response.errors = undefined
  //   console.error(graphQLErrors[0].message)
  // }

  if (errorCodes.includes('UNAUTHORIZED')) {
    writeDefaultsToCache()

    const { alert } = parseError(error)
    alertVar({
      icon: alert?.icon || null,
      message: alert?.message || null,
      title: alert?.title || null,
      variant: 'failure',
      isOpen: true
    })
  }
})

const apiUrl = getApiBaseUrl()

const httpLink = createUploadLink({ uri: `${apiUrl}/graphql` })

const client = new ApolloClient({
  cache,
  link: from([
    authLink,
    // installationLink,
    environmentLink,
    errorLink,
    retryLink,
    graphqlErrorLink,
    httpLink as any // TODO: Remove any later: https://github.com/jaydenseric/apollo-upload-client/pull/175
  ]),
  resolvers,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      // Pre 2.3 apollo client version, error policy ‘all’  was not respected
      // and by default ‘none’ was set.
      // In order to replicate 2.0.0-rc errorPolicy, ‘none’ is set as current policy.
      // https://github.com/apollographql/apollo-client/pull/7003
      errorPolicy: 'none'
    }
  }
})

client.onClearStore(writeDefaultsToCache)
client.onResetStore(writeDefaultsToCache)

writeDefaultsToCache()

export { cache }

export default client
