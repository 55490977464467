import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

enum AppPlatform {
  BROWSER = 'BROWSER',
  MOBILE = 'MOBILE',
  SERVER = 'SERVER'
}

enum AppTechnology {
  ANGULAR = 'ANGULAR',
  FLUTTER = 'FLUTTER',
  GO = 'GO',
  JAVASCRIPT = 'JAVASCRIPT',
  NATIVE_ANDROID = 'NATIVE_ANDROID',
  NATIVE_IOS = 'NATIVE_IOS',
  NODE = 'NODE',
  PHP = 'PHP',
  PYTHON = 'PYTHON',
  REACT = 'REACT',
  REACT_NATIVE = 'REACT_NATIVE',
  REMIX = 'REMIX',
  RUBY = 'RUBY',
  RUST = 'RUST',
  VUE = 'VUE',
  JAVA = 'JAVA',
  OTHER = 'OTHER'
}

const reqLogo = require.context('assets/images/integrations', true, /\.png$/)

const getAppIcon = (identifier = '') => {
  if (!identifier) return null

  try {
    return reqLogo(`./${identifier}.png`).default
  } catch {
    return null
  }
}

const CORE_APP_ID = '9a335276-0788-489a-9ea4-6101c8adbec9'
const INTERNAL_APP_IDS = {
  'account-center': '5fa36838-8e3d-4919-93d6-92d38652706d',
  experimentation: 'fa01bcaa-bebe-11eb-8529-0242ac130003',
  analytics: '75f5ad1a-ad09-46ef-b01a-50d28f341888',
  automation: 'af2e1b4b-6c28-498a-b3f3-a8ef5852976c',
  billing: 'b2fde93d-8aea-434c-a2e9-003f52a9f112',
  messaging: 'e8dec32b-2521-47f9-bc98-519548461591',
  cms: '130cd925-fc98-4624-bc69-6a2af00b74e2',
  'api-hub': '7d740451-ecc0-457e-a2e2-e4ea4901836d',
  'database-hub': '2a4f63f1-8182-4570-927c-d7bd4317c963'
}
const EXTERNAL_APP_IDS = {
  mailjet: '5e9b2ff7-b236-4042-8b1d-22c6cf8cb637',
  mailgun: '96327a6a-f557-4174-b8d6-96f861f0b163',
  mandrill: '1379ba2d-667d-4f11-9913-6790e94da3ba',
  postmark: 'fd42a685-e332-43c7-a3a3-0df91ade5f22',
  sendgrid: 'a990a985-fa74-4d83-a44c-e20b5658cd13',
  amazon_ses: '817783db-b2a3-40f7-927d-c4123eca52c7',
  amazon_s3: '776f43ec-1ad3-4566-a2b8-907e637be774',
  google_cloud_storage: '02414509-ee45-4c59-b732-ff9bf0b8998a',
  firebase: 'ecdd629b-d6a4-4db4-baeb-7af3cbf5454d',
  twilio: '52d73c8b-0dc6-474f-97d9-9679380768d3',
  exotel: '9aa99d54-fac6-4f09-a3e6-6581d9537b7a',
  kaleyra: 'dc3bdf12-9421-479b-bfae-1db55cbe7fe2',
  valuefirst: '4c3821c5-1182-43d9-adbe-f1ab1de5f348',
  razorpay: '5bde9a82-86e2-4725-8b88-97fcabf026e6',
  stripe: '81ceb475-661b-4d06-a52e-5b626f0f2cbb',
  apple_in_app_purchases: 'f57224d3-2769-4851-bebf-9c4c55213be2',
  google_in_app_purchases: '73555057-c528-417f-b292-b29ee9f145f5',
  mux: '4c3df640-9376-4f49-8a2d-981ac8c927ca',
  wati: '2d44189b-d0c6-4066-a47c-5745aac4a54d',
  smtp: '423a455e-14fc-49a0-9280-cbac503c721e',
  postgresql: 'ffed0937-d726-426d-8584-03736cbf2e19',
  mysql: 'd3791326-1db9-49ed-a321-bd472541276b',
  rest: '8d2f8e2a-7c72-4b3e-9d5f-6b87a499d5b7',
  graphql: 'bbec7a4b-2980-42d9-82d2-11f1ad449543',
  mongodb: '454180d5-2e6c-45b1-b198-74a8a3ab2b79',
  bigquery: '46cedc52-f085-46c0-a2dc-b51dc95fb40a'
}

const COMMON_APP_IDS = {
  templates: INTERNAL_APP_IDS.messaging
}

const APP_IDS = {
  core: CORE_APP_ID,
  ...INTERNAL_APP_IDS,
  ...EXTERNAL_APP_IDS,
  ...COMMON_APP_IDS
}

const APP_CATEGORIES_ID = {
  Product: '686df5df-4810-4670-a9e6-3a517b4e7324',
  Databases: 'd704f003-73e8-45f9-b3b5-d31992776e00',
  APIs: '07a8e0d6-c103-4183-8d4e-3578418fdcf9',
  PaymentGateways: 'fe465f23-d26e-4182-bfca-23f9c33fc18e',
  StorageProviders: '601adbe8-5dc0-4098-afdb-e6bfe5b72e6c',
  Custom: 'b9694083-4aff-4602-860b-bd34b0d164d9'
} as const

const CUSTOM_CATEGORY_ID = APP_CATEGORIES_ID.Custom

class App extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required()
  })
}

export const APP_LIST_LIMIT = 100

export {
  APP_CATEGORIES_ID,
  APP_IDS,
  AppPlatform,
  AppTechnology,
  CORE_APP_ID,
  CUSTOM_CATEGORY_ID,
  getAppIcon
}

export default App
